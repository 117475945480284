import React, { useState, useMemo, useEffect } from 'react'
import axios from 'axios'
import { Modal } from 'react-bootstrap'
import config from '../config'
import { useNavigate, useParams } from 'react-router-dom'
import _ from 'lodash'
import { setEmail } from '../redux/userSlice'
import { useDispatch } from 'react-redux'
import { joinClassNames, getContrastingTextForBackground } from 'utils'

const RegistrationPopup = ({ showModal, handleClose, displaySettings }) => {
  const navigate = useNavigate()
  const params = useParams()

  const [textColorClass, setTextColorClass] = useState('text-black')

  const backgroundColor = useMemo(() => {
    let bgColor = displaySettings?.displayProfileBackground
      ? displaySettings.displayRosterBackgroundColor
      : 'white'
    return bgColor
  }, [displaySettings])

  const backgroundColorClass = `bg-${backgroundColor}`

  useEffect(() => {
    const contrastingTextColor = getContrastingTextForBackground(backgroundColor)
    setTextColorClass(`text-${contrastingTextColor}`)
  }, [displaySettings.displayProfileBackground])

  const dispatch = useDispatch()

  const blankForm = {
    name: '',
    email: '',
    organization: '',
  }

  const [data, setData] = useState({ ...blankForm })

  const [formSent, setFormSent] = useState(false)
  const [error, setError] = useState(null)

  const handleSubmit = async (e) => {
    e.preventDefault()

    axios.post(`/api/register-sheet-viewer`, {
      email: data.email,
      talentSheetId: params.talentSheetId,
    })

    if (!_.isEmpty(data.email.trim())) {
      localStorage.setItem('userEmail', data.email)
      dispatch(setEmail(data.email))
      // handleClose(true)
    }
  }

  return (
    <Modal size="lg" show={showModal} onHide={handleClose}>
      <Modal.Body
        style={{
          padding: 0,
        }}
      >
        <div className={joinClassNames(['container', backgroundColorClass, 'p-2'])}>
          <div className="logo-container text-center mb-1">
            <img
              style={{
                maxWidth: '200px',
              }}
              src={displaySettings.image_url || config.BRAND_LOGO_URL}
              alt="Company Logo"
              className="company-logo"
            />
          </div>

          {formSent ? (
            <div className={textColorClass}>
              <h2 className="text-center">Welcome to the Gayborhood!</h2>
              <div className="row d-flex justify-content-center ">
                <div className="col-12 col-md-9 d-flex justify-content-center align-items-center p-4">
                  <p className="fs-4">Please check your email for a link to continue</p>
                </div>
              </div>
            </div>
          ) : (
            <div className="row d-flex justify-content-center ">
              <div
                className={joinClassNames([
                  'col-12 col-md-9 d-flex justify-content-center align-items-center',
                  textColorClass,
                  'welcome-box p-2',
                ])}
              >
                <div className="fs-5">
                  <h2 className="text-center">View Talent Sheet</h2>
                  <p>To view this roster of creators, please provide your email.</p>

                  <div className="d-flex justify-content-center align-items-center mb-3">
                    <form className="w-75" onSubmit={handleSubmit}>
                      <div className="mb-3">
                        <label htmlFor="email" className="form-label">
                          Email Address
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          id="email"
                          value={data.email}
                          onChange={(e) => setData({ ...data, email: e.target.value })}
                          required
                        />
                        <div className="invalid-feedback">Please enter a valid email.</div>
                      </div>
                      <button type="submit" className="btn btn-light btn-lg">
                        View Sheet
                      </button>
                    </form>
                  </div>
                  {error && <div className="error">{error}</div>}
                </div>
              </div>
            </div>
          )}
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default RegistrationPopup
