import _ from 'lodash'
import axios from 'axios'
import produce from 'immer'
import { useState } from 'react'

function RequestTalent() {
  const blankForm = {
    name: '',
    handle: '',
    socials: {
      primary: {
        id: 'primary',
        platform: 'Instagram',
        url: '',
      },
    },
    note: '',
  }

  const [data, setData] = useState(blankForm)
  const [socialCounter, setSocialCounter] = useState(1)
  const [formSent, setFormSent] = useState(false)
  const [error, setError] = useState(null)

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const hasDuplicates = checkDuplicates()
      if (hasDuplicates) {
        setError('You cannot include the same platform more than once')
        return
      }
      if (Object.keys(data.socials).length === 0) {
        setError('You must include at least one social platform')
        return
      }

      const formData = {
        ...data,
        socials: _.map(data.socials, (social) => {
          return {
            platform: social.platform.toLowerCase(),
            url: social.url,
            primary: social.id === 'primary',
          }
        }),
      }

      if (!_.every(formData.socials.map((social) => social.url.trim() !== ''))) {
        setError('You must include a handle or URL for each platform')
        return
      }
      axios.post('/api/user/request-influencer', formData).then((r) => {
        setFormSent(true)
        setData(blankForm)
      })

      return
    } catch (e) {
      console.log(e)
      setError('Something went wrong, sorry. Please contact us about adding an talent')
    }
  }

  const checkDuplicates = () => {
    const used = _.map(data.socials, (social) => social.platform)
    const hasDuplicates = used.length !== _.uniq(used).length
    return hasDuplicates
  }

  const getRemainingPlatforms = () => {
    return ['Instagram', 'TikTok', 'YouTube', 'Facebook', 'Twitter']
  }

  const socialDisplay = () => {
    const otherKeys = _.without(Object.keys(data.socials), 'primary')
    return ['primary', ...otherKeys]
  }

  if (formSent) {
    return (
      <div className="container mt-4">
        <div className="row justify-content-center mx-1">
          <div className="col-md-10 col-lg-8 signin-main">
            <h2 className="text-center">Submitted</h2>
            <p>
              We have received your request and will get to work on adding that talent to your
              organization. We'll let you know if there are any issues.
            </p>
            <button className="btn btn-primary" onClick={() => setFormSent(false)}>
              Make Another Request
            </button>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="container mt-4">
      <div className="row justify-content-center mx-1">
        <div className="col-md-10 col-lg-8 signin-main">
          <h1 className="mb-3 text-center">Request to Add Talent</h1>
          <div className="row justify-content-center">
            <div className="col-12">
              <form className="influencer-request-form" onSubmit={handleSubmit}>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    autoFocus
                    value={data.name}
                    placeholder="talent display name (required)"
                    onChange={(e) => {
                      setData({
                        ...data,
                        name: e.target.value,
                      })
                    }}
                  />
                </div>
                <div className="mb-3">
                  <input
                    type="text"
                    className="form-control"
                    value={data.handle}
                    placeholder="primary handle"
                    onChange={(e) => {
                      setData({
                        ...data,
                        handle: e.target.value,
                      })
                    }}
                  />
                </div>
                <hr />
                <p>Talent's primary platform (required):</p>
                {socialDisplay().map((key, i) => {
                  const social = data.socials[key]
                  return (
                    <div key={`social-${social.id}`} className="mb-3">
                      <select
                        className="form-select mb-1"
                        value={data.socials[social.id].platform}
                        onChange={(e) => {
                          const nextData = produce(data, (draftState) => {
                            draftState.socials[social.id].platform = e.target.value
                          })
                          setData(nextData)
                        }}
                      >
                        {getRemainingPlatforms().map((v) => {
                          return (
                            <option key={`platform-${v.toLowerCase()}`} value={v}>
                              {v}
                            </option>
                          )
                        })}
                      </select>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="profile URL or handle"
                        value={data.socials[social.id].url}
                        onChange={(e) => {
                          const nextData = produce(data, (draftState) => {
                            draftState.socials[social.id].url = e.target.value
                          })
                          setData(nextData)
                        }}
                      />
                      {social.id !== 'primary' && (
                        <div className="d-flex w-100 justify-content-end pe-2">
                          <button
                            onClick={(e) => {
                              e.preventDefault()
                              const nextData = produce(data, (draftState) => {
                                delete draftState.socials[social.id]
                              })
                              setData(nextData)
                            }}
                            className="btn btn-sm btn-danger mt-1"
                          >
                            Remove
                          </button>
                        </div>
                      )}

                      <hr />
                    </div>
                  )
                })}
                <div className="d-flex justify-content-center">
                  <button
                    className="btn btn-primary mb-2"
                    onClick={(e) => {
                      e.preventDefault()
                      const nextData = produce(data, (draftState) => {
                        draftState.socials[socialCounter] = {
                          id: socialCounter,
                          platform: 'Instagram',
                          url: '',
                        }
                      })
                      setData(nextData)
                      setSocialCounter(socialCounter + 1)
                    }}
                  >
                    Add Another Social Platform
                  </button>
                </div>

                <div>
                  <textarea
                    className="form-control"
                    placeholder="Additional notes (optional)"
                    value={data.note}
                    onChange={(e) => setData({ ...data, note: e.target.value })}
                  />
                </div>
                <div className="message-box mt-2">
                  {error && <div className="error">{error}</div>}
                </div>

                <div className="d-flex justify-content-center">
                  <input className="btn btn-primary my-3" type="submit" value="Submit Request" />
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default RequestTalent
