import _, { map } from 'lodash'
import { useNavigate, useParams, Link } from 'react-router-dom'
import { useEffect, useRef, useState, useMemo } from 'react'

import LoadingContainer from './components/LoadingContainer'
import { Rings } from 'react-loader-spinner'
import {
  getContrastColor,
  getContrastingTextForBackground,
  joinClassNames,
  scaleColor,
  getColorHex,
} from './utils'
import { useInView } from 'react-intersection-observer'
import { Talent } from 'helpers/Talent'
import mixpanel from './mixpanelBrowser'
import { sendEvent } from 'analytics'
import TalentSheetRosterItemFullCover from 'TalentSheetRosterItemFullCover'
import TalentSheetRosterItemCircular from 'TalentSheetRosterItemCircular'
import { useSelector, useDispatch } from 'react-redux'
import { fetchSingleProfileForSheet } from './redux/talentSheetSlice'

const BlankPlaceholder = () => {
  return (
    <div className="col-md-6 col-lg-4 roster-influencer-holder circle-portraits mb-4">
      <div
        style={{ height: '100%' }}
        className="d-flex flex-column justify-content-between p-3 roster-box bg-white rounded shadow"
      >
        <div className="d-flex flex-column justify-content-around pt-2">
          <div className="selectable portrait-rounded text-center mb-3">
            <div className="image-holder">
              {/* <img src="placeholder-image-url" alt="Placeholder" /> */}
            </div>
          </div>

          <div className="d-flex flex-column">
            <div className="d-flex flex-wrap justify-content-center align-items-center social-follower-counts text-center">
              <div className="d-flex flex-column align-items-center mb-1 ms-1 roster-social-icon">
                <div className="mb-1">
                  <a className="text-black" href="#">
                    ...
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const RenderRosterItems = ({
  talentSheet,
  totalPages = 1,
  currentGroup,
  pageLimit,
  talentLoading,
  setTalentLoading,
  sheetIsLoading,
  loadNextGroup,
  source = 'Talent Sheet',
  displaySettings = {
    displayAccentColor: 'shine-gold',
  },
}) => {
  const params = useParams()
  const navigate = useNavigate()
  const { ref, inView, entry } = useInView({
    /* Optional options */
    threshold: 0,
    onChange: (inView, entry) => {
      if (inView && !talentLoading) {
        setTalentLoading(true)
        loadNextGroup()
      }
    },
    initialInView: false,
  })
  const dispatch = useDispatch()

  const influencers = useSelector((state) => state.influencers.entities)
  const socials = useSelector((state) => state.socials.entities)

  const displayedInfluencers = talentSheet.influencer_ids.map((iid) => influencers[iid])
  // .filter((inf) => inf)

  const mainContainerRef = useRef(null)

  const RosterItem = useMemo(() => {
    if (displaySettings.displayUseOldRoundedPortraits) return TalentSheetRosterItemCircular
    return TalentSheetRosterItemFullCover
  }, [displaySettings])

  const backgroundColorStyle = useMemo(() => {
    let bgColorHex
    if (displaySettings.displayRosterBackgroundColor) {
      bgColorHex = getColorHex(displaySettings.displayRosterBackgroundColor)
      return { backgroundColor: bgColorHex }
    }

    bgColorHex = getColorHex(displaySettings.displayAccentColor)
    return { backgroundColor: scaleColor(bgColorHex, 92) }
  }, [displaySettings])

  const textColorClass = useMemo(() => {
    // Use bgColorHex from backgroundColorStyle, falling back to 'white' if not set.
    const bgColorHex = backgroundColorStyle.backgroundColor || 'white'
    const contrastingTextColor = getContrastingTextForBackground(bgColorHex)
    return `text-${contrastingTextColor}`
  }, [backgroundColorStyle])

  return (
    <>
      <div
        ref={mainContainerRef}
        className={joinClassNames([
          typeof backgroundColorStyle === 'string' ? backgroundColorStyle : null,
          'pt-2',
        ])}
        style={backgroundColorStyle}
      >
        <div className="container">
          <div
            className={joinClassNames([
              `row`,
              displaySettings.displayUseRosterFullPhoto ? 'gx-5' : null,
            ])}
          >
            {_.isEmpty(displayedInfluencers) && (
              <div className="d-flex justify-content-center">
                <Rings
                  height="80"
                  width="80"
                  color="#5863f8ff"
                  radius="6"
                  wrapperStyle={{}}
                  wrapperClass=""
                  visible={true}
                  ariaLabel="rings-loading"
                />
              </div>
            )}
            {map(displayedInfluencers, (influencer, i) => {
              if (!influencer) return <BlankPlaceholder key={`blank-${i}`} />

              const talent = new Talent(influencer, socials)
              const profile = {
                ...influencer,
              }
              profile.socials = talent.getSocials().allSocials

              return (
                <RosterItem
                  key={`influencer-${influencer.id}`}
                  specialText={_.get(talentSheet, ['notes', influencer.id], null)}
                  influencer={influencer}
                  handleClick={() => {
                    mixpanel.track(`${source} - Clicked Influencer`, {
                      influencer_id: influencer.id,
                      talent_sheet_id: params.talentSheetId,
                    })
                    dispatch(
                      fetchSingleProfileForSheet({
                        influencer_id: influencer.id,
                        salesheet_id: talentSheet.id,
                      }),
                    )

                    sendEvent('sheet_click_influencer', {
                      influencer_id: influencer.id,
                      sheet_id: params.talentSheetId,
                    })
                    window.scrollTo({ top: 0, left: 0, behavior: 'auto' })
                    navigate(`?influencer_id=${influencer.id}`)
                  }}
                  profile={profile}
                  displaySettings={_.get(talentSheet, 'settings', {})}
                  source={source}
                />
              )
            })}
          </div>
        </div>
        {currentGroup < totalPages && (
          <div ref={ref} className="d-flex justify-content-center w-100 py-4">
            <button className="btn btn-link" onClick={loadNextGroup}>
              {talentLoading ? 'Loading...' : 'Load More'}
            </button>
          </div>
        )}
        {sheetIsLoading && <LoadingContainer />}
      </div>
    </>
  )
}

export default RenderRosterItems
