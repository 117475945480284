import { Link, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'

import axios from 'axios'
import { setUser } from './redux/userSlice'
import PreLogin from 'PreLogin'

function NewUserConfirm() {
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [error, setError] = useState(null)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const [email, setEmail] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (password !== passwordConfirm) {
      setError('Passwords do not match')
      return
    }
    if (password.length < 6) {
      setError('Choose a longer password')
      return
    }

    try {
      const { data } = await axios.post('/api/user/confirm-account', {
        code: params.registrationCode,
        password,
        email,
      })
      dispatch(setUser(data))
      navigate('/')
    } catch (e) {
      console.log(e)
      setError('Bad sign-in information')
    }
  }

  useEffect(() => {
    const code = params.registrationCode
    axios
      .post('/api/user/registration', {
        code,
      })
      .then(({ data }) => {
        setEmail(data.email)
      })
      .catch(() => {
        setError(
          'Something went wrong while trying to find your account. Your registration code may be invalid. Please contact us.',
        )
      })
  }, [])

  return (
    <PreLogin>
      <h2 className="mb-5 text-center">Please set a password for your account</h2>
      <div className="row justify-content-center">
        <div className="col-10 col-sm-6">
          <div>
            <form className="sign-in-form" onSubmit={handleSubmit}>
              <div className="mb-3">
                <input
                  type="email"
                  className="form-control"
                  autoFocus
                  value={email}
                  placeholder="email"
                  readOnly
                />
              </div>
              <div className="mb-3">
                <input
                  type="password"
                  className="form-control"
                  placeholder="password"
                  value={password}
                  onChange={(e) => {
                    setPassword(e.target.value)
                  }}
                />
              </div>
              <div className="mb-3">
                <input
                  type="password"
                  className="form-control"
                  placeholder="confirm password"
                  value={passwordConfirm}
                  onChange={(e) => {
                    setPasswordConfirm(e.target.value)
                  }}
                />
              </div>
              <div className="d-flex justify-content-center">
                <input className="btn btn-primary my-3" type="submit" value="Confirm Account" />
              </div>
            </form>
          </div>
        </div>
        {error && <div className="error">{error}</div>}
        <div className="mt-4">
          <p>
            Already done this? Go to the <Link to="/">Main Page</Link>
          </p>
        </div>
      </div>
    </PreLogin>
  )
}

export default NewUserConfirm
