import axios from 'axios'
import { useState } from 'react'

function ForgotPassword() {
  const [email, setEmail] = useState('')
  const [error, setError] = useState(null)

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      const { data } = await axios.post('/api/user/account/forgot-password', {
        email,
      })
      setError('Password reset link has been sent to the email')
    } catch (e) {
      console.log(e)
      setError('Something went wrong. Please contact us about recovering your account.')
    }
  }

  return (
    <div className="login-container">
      <div className="container mt-4">
        <div className="row justify-content-center mx-1">
          <div className="col-md-10 col-lg-8 signin-main p-5">
            <h2 className="mb-3 text-center">Reset Password</h2>
            <p className="mb-5 text-center">
              If you lost your password or didn't receive the message to set up your account, enter
              your email address here and we will send an email allowing you to set a new password.
            </p>
            <div className="row justify-content-center">
              <div className="col-10 col-sm-6">
                <form className="sign-in-form" onSubmit={handleSubmit}>
                  <div className="mb-3">
                    <input
                      type="text"
                      className="form-control"
                      autoFocus
                      value={email}
                      placeholder="email"
                      onChange={(e) => {
                        setEmail(e.target.value)
                      }}
                    />
                  </div>
                  <div className="d-flex justify-content-center">
                    <input
                      className="btn btn-primary my-3"
                      type="submit"
                      value="Send Reset Link"
                    />
                  </div>
                </form>
                {error && <div className="error">{error}</div>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ForgotPassword
