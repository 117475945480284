import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import _ from 'lodash'
import axios from 'axios'

export const fetchCategories = createAsyncThunk('influencerCategories/fetchAll', async () => {
  const response = await axios.get('/api/categories')
  return response.data
})

export const categorySlice = createSlice({
  name: 'category',
  initialState: {
    categories: [],
    subcategories: {},
    team_categories: [],
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCategories.fulfilled, (state, { payload }) => {
      const subcats = {}
      _.forEach(payload.categories, (cat) => {
        cat.subcategories.forEach((sc) => {
          subcats[sc.id] = sc.name
        })
      })
      state.subcategories = subcats
      state.categories = payload.categories
      state.team_categories = payload.team_categories
    })
  },
})

export default categorySlice.reducer
