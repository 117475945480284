import _ from 'lodash'
import axios from 'axios'
import produce from 'immer'
import { useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router'
import { useDropzone } from 'react-dropzone'
import CommonFormWrapper from 'components/CommonFormWrapper'
import ConfirmModal from 'components/ConfirmModal'
import { removeInfluencerById } from 'redux/influencerSlice'
import Cropper from 'react-cropper'
import 'cropperjs/dist/cropper.css' // import the css for cropperjs
import { ProgressBar } from 'react-loader-spinner'
import { Talent } from 'helpers/Talent'

const RenderPortrait = ({ setUploadedKey, uploadedKey }) => {
  return (
    <div className="d-flex justify-content-center my-2">
      <div className="d-flex flex-column">
        <div className="text-center">
          <img
            style={{
              objectFit: 'cover',
              objectPosition: 'center',
              width: '200px',
              height: '200px',
              borderRadius: '50%',
            }}
            src={uploadedKey}
            alt="uploaded"
          />
        </div>

        {setUploadedKey && (
          <>
            <p className="text-bold mt-2">
              This portrait will replace the one currently shown on the profile page
            </p>
            <div className="text-center">
              <button
                className="btn btn-danger"
                onClick={(e) => {
                  e.preventDefault()
                  setUploadedKey(null)
                }}
              >
                Cancel
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  )
}

const ImageUploadAndCrop = ({ uploadedKey, setUploadedKey, imageSrc, setImageSrc }) => {
  const [cropper, setCropper] = useState(null)
  const [error, setError] = useState(null)
  const [uploadPending, setUploadPending] = useState(false)

  const { getRootProps, getInputProps } = useDropzone({
    accept: { 'image/*': [] },
    onDrop: async (acceptedFiles) => {
      acceptedFiles.map((file) =>
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        }),
      )

      const file = acceptedFiles[0]
      setImageSrc(file.preview)
    },
  })

  const uploadPhoto = async (file) => {
    if (!file) return
    try {
      const { data } = await axios.get('/api/media-upload', {
        params: {
          filename: file.name,
          type: file.type,
        },
      })

      const upload = await axios.put(data.url, file, {
        headers: {
          'x-amz-acl': 'public-read',
          'Content-Type': file.type,
        },
      })

      setUploadedKey(data.key)
    } catch (e) {
      console.log(e)
      setError('Something went wrong with the upload.')
    }
  }

  const cropImage = async (e) => {
    e.preventDefault()
    if (typeof cropper !== 'undefined') {
      // Get cropped image
      let canvas = cropper.getCroppedCanvas()

      // If the original dimensions are greater than 600px, resize the image
      if (canvas.width > 600 || canvas.height > 600) {
        let maxCanvas = document.createElement('canvas')
        let ctx = maxCanvas.getContext('2d')

        // Get the aspect ratio of the image
        let ratio = Math.min(600 / canvas.width, 600 / canvas.height)

        // Set the dimensions of the new canvas
        maxCanvas.width = canvas.width * ratio
        maxCanvas.height = canvas.height * ratio

        // Draw the image onto the new canvas, effectively resizing it
        ctx.drawImage(
          canvas,
          0,
          0,
          canvas.width,
          canvas.height,
          0,
          0,
          maxCanvas.width,
          maxCanvas.height,
        )

        // Replace the original canvas with the resized one
        canvas = maxCanvas
      }

      // Convert the canvas to a blob
      const content = await new Promise((resolve) => canvas.toBlob(resolve, 'image/jpeg'))

      const file = new File([content], 'myCroppedPhoto.jpg', { type: 'image/jpeg' })

      // Upload cropped file
      setUploadPending(true)
      await uploadPhoto(file)
      setUploadPending(false)
    }
  }

  if (uploadPending) {
    return (
      <div className="d-flex justify-content-center">
        <ProgressBar
          height="80"
          width="80"
          ariaLabel="progress-bar-loading"
          wrapperStyle={{}}
          wrapperClass="progress-bar-wrapper"
          borderColor="#F4442E"
          barColor="#51E5FF"
        />
      </div>
    )
  }

  return (
    <div>
      <div {...getRootProps({ className: 'dropzone' })}>
        <input {...getInputProps()} />
        <p className="selectable">Click to select a file from your computer</p>
      </div>
      {imageSrc && (
        <div>
          {uploadedKey ? (
            <div className="d-flex justify-content-center my-2">
              <div className="d-flex flex-column">
                <div className="text-center">
                  <img
                    style={{
                      objectFit: 'cover',
                      objectPosition: 'center',
                      width: '200px',
                      height: '200px',
                      borderRadius: '50%',
                    }}
                    src={`https://tpp-uploads.us-east-1.linodeobjects.com/${uploadedKey}`}
                    alt="uploaded"
                  />
                </div>

                <p className="text-bold mt-2">
                  This portrait will replace the one currently shown on the profile page
                </p>
                <div className="text-center">
                  <button
                    className="btn btn-danger"
                    onClick={(e) => {
                      e.preventDefault()
                      setUploadedKey(null)
                    }}
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          ) : (
            <div className="mb-3">
              <Cropper
                src={imageSrc}
                style={{ maxHeight: '600px', width: '100%' }}
                aspectRatio={1}
                guides={true}
                onInitialized={(instance) => {
                  setCropper(instance)
                }}
              />
              <div className="d-flex justify-content-center">
                <button className="btn btn-primary mt-2" onClick={cropImage}>
                  Confirm Portrait Framing
                </button>
                <button
                  className="btn btn-danger mt-2 ms-2"
                  onClick={(e) => {
                    e.preventDefault()
                    setImageSrc(null)
                  }}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
        </div>
      )}
      {error && <p>{error}</p>}
    </div>
  )
}

const removeAtIndex = (arr, index) => {
  return arr.filter((_, i) => i !== index)
}

const isSocialEmpty = (social) => {
  return social.url === ''
}

const socialsEmpty = (socials) => {
  if (socials.length === 0) {
    return true
  }
  const allSocialsEmpty = socials.every(isSocialEmpty)
  return allSocialsEmpty
}

function RequestPortraitChange() {
  const params = useParams()
  const navigate = useNavigate()
  const influencer = useSelector((state) => state.influencers.entities[params.influencerId])
  const dispatch = useDispatch()
  const socials = useSelector((state) => state.socials.entities)

  const [showConfirmModal, setShowConfirmModal] = useState(false)
  const [influencerRemoved, setInfluencerRemoved] = useState(false)
  const [imageSrc, setImageSrc] = useState(null)
  const [extraUploadKeys, setExtraUploadKeys] = useState([])

  const blankForm = {
    influencer_id: params.influencerId,
    socials: [
      {
        platform: 'Instagram',
        url: '',
      },
    ],
    note: '',
  }

  const [data, setData] = useState(blankForm)
  const [formSent, setFormSent] = useState(false)
  const [error, setError] = useState(null)

  const [uploadedKey, setUploadedKey] = useState(null)

  if (!influencer) {
    return <div>Loading...</div>
  }

  const changePhoto = async () => {
    const result = await axios.post('/api/user/replace-portrait', {
      influencer_id: influencer.id,
      uploadedKey,
    })

    if (result.data.success) {
      setFormSent(true)
    } else {
      setError('Something went wrong, sorry. Please contact us about removing talent')
    }
  }

  const talentEntity = new Talent(influencer, socials)

  return (
    <CommonFormWrapper>
      {formSent ? (
        <div className="text-center">
          <h2 className="text-center my-3">Portrait Changed</h2>
          <p>The new portrait you uploaded is now visible.</p>
          <button
            className="btn btn-primary"
            onClick={() => {
              navigate(`/influencers/profile/${influencer.id}`)
            }}
          >
            Back to Profile
          </button>
        </div>
      ) : (
        <>
          <h1 className="mb-3 text-center">Change Portrait</h1>
          <div className="row justify-content-center">
            <div className="col-12">
              <form
                onSubmit={(e) => {
                  e.preventDefault()
                  changePhoto(e)
                }}
              >
                {!uploadedKey && <RenderPortrait uploadedKey={talentEntity.getPhotoUrl()} />}

                <div className="d-flex flex-column justify-content-center">
                  <ImageUploadAndCrop
                    uploadedKey={uploadedKey}
                    setUploadedKey={setUploadedKey}
                    imageSrc={imageSrc}
                    setImageSrc={setImageSrc}
                  />
                </div>
                <div className="d-flex justify-content-center">
                  {uploadedKey && (
                    <>
                      <button className="btn btn-lg btn-primary" type="submit">
                        Confirm Portrait Change
                      </button>
                    </>
                  )}
                </div>
              </form>
            </div>
          </div>
        </>
      )}
    </CommonFormWrapper>
  )
}

export default RequestPortraitChange
