import {
  ArcElement,
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js'
import { useEffect, useState } from 'react'

import ChartDataLabels from 'chartjs-plugin-datalabels'
import { FaArrowLeft } from 'react-icons/fa'
import Infobox from './Infobox'
import { Link } from 'react-router-dom'
import Socialbox from './Socialbox'
import _ from 'lodash'
import axios from 'axios'
import { setInfluencerTeamSettings } from '../redux/influencerSlice'
import { useDispatch } from 'react-redux'
import { getInfoBoxKeys } from 'helpers/Platform'
import RenderMarkdown from 'components/RenderMarkdown'
import ClickToCopy from 'components/ClickToCopy'

ChartJS.register(ArcElement, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend)
ChartJS.register(ChartDataLabels)

function InstagramPost({ post }) {
  return (
    <div className="col-md-4 mb-4">
      <a href={post.link} target="_blank" rel="noreferrer">
        <div className="instagram-post-holder border shadow-sm">
          <div
            className="image-holder"
            style={{ backgroundImage: `url(${post.thumbnail})` }}
          ></div>
          <div className="text-holder">
            <p className="instagram-text text-black">{post.text}</p>
          </div>
        </div>
      </a>
    </div>
  )
}

export default function PublicProfile({
  selectedPlatform,
  setSelectedPlatform,
  influencer,
  socials,
  openAccess = true,
  showBackArrow = false,
  showEmail = false,
  onBack = () => {},
  settings = {},
  user,
}) {
  const dispatch = useDispatch()
  const [showPosts, setShowPosts] = useState('top_posts')

  const [editingBoxes, setEditingBoxes] = useState(false)

  const handleSwitchPlatform = (id) => {
    setSelectedPlatform(id)
    setShowPosts('top_posts')
  }

  const primarySocial = socials[influencer.primary_account_id]

  const otherSocials = _.get(influencer, 'non_primary_accounts', []).map(
    (account_id) => socials[account_id],
  )

  const influencerSocials = [primarySocial, ...otherSocials]

  const displayedAccountId = selectedPlatform || influencer.primary_account_id
  const selectedSocial = socials[displayedAccountId]

  if (!selectedSocial) {
    return <div>Loading...</div>
  }

  const displayedPlatform = selectedSocial.platform

  let available_post_types = [
    {
      display: 'Top',
      id: 'top_posts',
    },
    {
      display: 'Sponsored',
      id: 'commercial_posts',
    },
    {
      display: 'Reels',
      id: 'top_reels',
    },
  ]

  available_post_types = available_post_types.filter((post_type) => {
    const available = _.get(selectedSocial, [post_type.id])
    return available && available.length > 0
  })

  let displayedPosts = _.get(selectedSocial, [showPosts], [])

  const audience_followers = _.get(selectedSocial, 'audience_followers', {})

  let description = _.isEmpty(influencer.custom_description)
    ? _.get(influencer, 'description', '')
    : _.get(influencer, 'custom_description')

  let infoBoxes = getInfoBoxKeys(selectedSocial.platform)

  const overViewTextFields = []

  if (!_.isEmpty(influencer.country)) {
    overViewTextFields.push(influencer.country)
  }

  if (!_.isEmpty(influencer.age_range)) {
    overViewTextFields.push(influencer.age_range)
  }
  if (!_.isEmpty(influencer.gender)) {
    overViewTextFields.push(influencer.gender)
  }

  const overViewText = overViewTextFields.join(' | ')

  const disabledInfoBoxes = _.get(influencer, 'team_settings.disabledInfoBoxes', [])
  const setDisabledInfoBoxes = (disabledInfoBoxes) => {
    dispatch(
      setInfluencerTeamSettings({
        influencer_id: influencer.id,
        updates: { disabledInfoBoxes },
      }),
    )
  }

  const advancedDataAvailable = (selectedSocial, keys) => {
    const available = keys
      .map((k) => {
        return !_.isEmpty(_.get(selectedSocial, ['audience_followers', k]))
      })
      .filter((r) => r)
    return available.length > 0
  }

  const portrait_url = influencer.custom_picture
    ? influencer.custom_picture
    : primarySocial.picture_main_url

  const excludedPostIds = _.get(settings, 'excluded_post_ids', [])

  displayedPosts = displayedPosts.filter((post) => {
    return !excludedPostIds.includes(post.post_id)
  })

  const displayName = influencer.team_settings?.custom_display_name
    ? influencer.team_settings.custom_display_name
    : influencer.fullname

  return (
    <div>
      <div className="talent-profile">
        <div className="container py-4">
          {!openAccess && (
            <>
              <div className="d-flex w-100 justify-content-end my-2">
                <Link to={`/request/${influencer.id}`} className="btn btn-danger">
                  Request Profile Change
                </Link>
              </div>

              <div>
                {/* <Link to={`/public/profile/${influencer.id}`}>Public Profile Link</Link> */}
                {user && (
                  <span className="ms-3 fs-5">
                    <Link to={`/public/profile/${influencer.id}/team/${user.selectedTeamId}`}>
                      Shareable Profile Link
                    </Link>
                    <ClickToCopy
                      iconId={'share-profile-copy-icon'}
                      textToCopy={`${window.location.origin}/public/profile/${influencer.id}/team/${user.selectedTeamId}`}
                    />
                  </span>
                )}
              </div>
            </>
          )}
          {showBackArrow && (
            <div className="selectable">
              <span data-purpose="back-arrow" onClick={onBack}>
                <FaArrowLeft style={{ fontSize: '1.4em' }} />
              </span>
            </div>
          )}{' '}
          <div className="row text-center justify-content-center align">
            <div className="col-8">
              <p className="text-bold">{influencer.note}</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 text-center mb-3 mb-md-0">
              <div className="portrait-rounded">
                <img src={portrait_url} />
              </div>
            </div>

            <div className="col-md-8 order-md-first">
              <div className="d-flex flex-column">
                <h1>{displayName}</h1>
                {/* {!settings.hideInfluencerBio && <p>{overViewText}</p>} */}
                {showEmail && influencer.contact_email && (
                  <p style={{ fontSize: '.85em' }} className="text-bold">
                    Email contact: {influencer.contact_email}
                  </p>
                )}
              </div>
              <div>
                {description && (
                  <>
                    <RenderMarkdown markdownText={description} />
                  </>
                )}
                {!openAccess && (
                  <p>
                    <Link
                      className="btn btn-primary p-2 m-2 rounded"
                      data-purpose="talent-custom-description"
                      to={`/talent/edit-description/${influencer.id}`}
                    >
                      Write/edit custom description
                    </Link>
                  </p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ marginTop: '-30px' }}
        className="container bg-white shadow-sm d-flex justify-content-center"
      >
        <div className="w-100 p-4">
          <h2 className="text-center">Social Reach</h2>
          {influencerSocials.length > 1 && (
            <p style={{ fontSize: '1.05em' }}>
              Click logo to show details for that platform below
            </p>
          )}
          <div className="row">
            {influencerSocials.map((social, i) => {
              return (
                <div key={`social-${social.id}`} className="social-box col col-md-4 print-col-6">
                  <Socialbox
                    profile={social}
                    setShowPlatform={() => {
                      handleSwitchPlatform(social.id)
                    }}
                    selected={social.id == displayedAccountId}
                  />
                </div>
              )
            })}
          </div>
        </div>
      </div>
      <div className="container mt-4 audience-stats-container">
        <div className="row">
          {advancedDataAvailable(selectedSocial, infoBoxes) ? (
            infoBoxes.map((infoKey, i) => {
              if (!editingBoxes && disabledInfoBoxes.includes(infoKey)) return null
              return (
                <div key={`infobox-${i}`} className="col-12 col-sm-6 col-md-4 my-3">
                  {!openAccess && editingBoxes && (
                    <div className="selectable text-end">
                      {/* <span className="me-2">
                        <input
                          id={`checkbox-${infoKey}`}
                          className="form-check-input"
                          type="checkbox"
                          checked={!disabledInfoBoxes.includes(infoKey)}
                          onChange={(e) => {
                            if (disabledInfoBoxes.includes(infoKey)) {
                              setDisabledInfoBoxes(disabledInfoBoxes.filter((k) => k != infoKey))
                            } else {
                              setDisabledInfoBoxes([...disabledInfoBoxes, infoKey])
                            }
                          }}
                        />
                      </span>
                      <label htmlFor={`checkbox-${infoKey}`}>Show?</label> */}
                    </div>
                  )}
                  <Infobox
                    key={infoKey}
                    influencer={influencer}
                    infoKey={infoKey}
                    audience_followers={audience_followers}
                    advanced_data={{
                      engagement_rate: selectedSocial.engagement_rate,
                      avg_likes: selectedSocial.avg_likes,
                      avg_views: selectedSocial.avg_views,
                    }}
                    // advanced_data={_.get(influencer, ['advanced_data', displayedAccountId])}
                  />
                </div>
              )
            })
          ) : (
            <p>Advanced statistics not available</p>
          )}
        </div>
        {!openAccess && (
          <div className="row mt-4 justify-content-center">
            <>
              <div className="d-flex flex-row justify-content-center">
                {editingBoxes && (
                  <ul className="list-group w-50 my-2">
                    {[
                      { value: 'audience_gender', title: 'Audience Gender' },
                      { value: 'audience_age', title: 'Audience Percentage By Age' },
                      { value: 'audience_countries', title: 'Top Countries' },
                      { value: 'engagement_rate', title: 'Engagement Rate' },
                      { value: 'audience_interests', title: 'Audience Interests' },
                      { value: 'audience_cities', title: 'Top Cities' },
                      { value: 'avg_views', title: 'Average Views', note: 'Youtube / TikTok' },
                      { value: 'avg_likes', title: 'Average Likes', note: 'Youtube / TikTok' },
                    ].map((item) => {
                      return (
                        <li key={`key-${item.value}`} className="list-group-item">
                          <input
                            id={`checkbox-${item.value}`}
                            className="form-check-input me-3"
                            type="checkbox"
                            checked={!disabledInfoBoxes.includes(item.value)}
                            onChange={(e) => {
                              if (disabledInfoBoxes.includes(item.value)) {
                                setDisabledInfoBoxes(
                                  disabledInfoBoxes.filter((k) => k != item.value),
                                )
                              } else {
                                setDisabledInfoBoxes([...disabledInfoBoxes, item.value])
                              }
                            }}
                          />
                          <label htmlFor={`checkbox-${item.value}`} className="selectable">
                            {item.title}
                            {item.note && <span className="ms-3">{`(${item.note})`}</span>}
                          </label>
                        </li>
                      )
                    })}
                  </ul>
                )}
              </div>
              <button
                onClick={() => {
                  if (editingBoxes) {
                    axios.post('/api/team/influencer-settings', {
                      influencer_id: influencer.id,
                      settings: influencer.team_settings,
                    })
                  }
                  setEditingBoxes(!editingBoxes)
                }}
                style={{ width: '180px' }}
                className="btn btn-primary"
              >
                {editingBoxes ? 'Save' : 'Show/Hide Widgets'}
              </button>
            </>
          </div>
        )}
        <hr />
        {!openAccess && (
          <Link to={`/talent/${influencer.id}/edit-posts`} className="btn btn-primary">
            Show/Hide Posts
          </Link>
        )}

        <div className="row influencer-posts-container d-print-none">
          <h2 className="text-center mb-3">Posts</h2>
          <ul className="nav nav-tabs mb-3">
            {available_post_types.map((post_type) => {
              return (
                <li key={`select-${post_type.id}`} className="nav-item">
                  <button
                    className={`nav-link text-black ${showPosts === post_type.id ? 'active' : ''}`}
                    onClick={() => {
                      setShowPosts(post_type.id)
                    }}
                  >
                    {post_type.display}
                  </button>
                </li>
              )
            })}
          </ul>
          {!_.isEmpty(displayedPosts) ? (
            displayedPosts.slice(0, 6).map((post) => {
              return <InstagramPost key={`post-${post.post_id}`} post={post} />
            })
          ) : (
            <p>No posts available</p>
          )}
        </div>
      </div>
    </div>
  )
}
