import SocialIcon from '../components/SocialIcon'
import { safelyMillify } from '../utils'
import { getDisplayName } from 'helpers/Social'

export default function Socialbox({ profile, setShowPlatform, selected = true }) {
  return (
    <div className="d-flex">
      <div
        onClick={() => {
          setShowPlatform(profile.social_id)
        }}
        className={`profile-social-icon me-3 selectable`}
      >
        <SocialIcon size={selected ? '1.2em' : '1em'} platform={profile.platform} />
      </div>
      <div className="d-flex flex-column">
        <div className={`pt-2 mb-2 ${selected ? 'text-bold' : ''}`}>
          <a style={{ color: '#000' }} href={profile.profile_url} target="_blank">
            {getDisplayName(profile)}
          </a>
        </div>
        {profile.followers && <div>{safelyMillify(profile.followers)} followers</div>}
      </div>
    </div>
  )
}
