function NotFound() {
  return (
    <div className="container mt-4">
      <h1>Not Found!</h1>
      <p>Something is wrong. The page you are looking for was not found. Sorry.</p>
    </div>
  )
}

export default NotFound
