import _, { filter } from 'lodash'

const applyFilters = ({
  influencers,
  filteringTags,
  nameFilter,
  taggedInfluencers,
  excludedIds,
  orderBy,
  sortProperty,
  sortDirection,
}) => {
  let filteredIds = _.map(influencers, 'id')

  for (const filterTag of filteringTags) {
    filteredIds = _.intersection(filteredIds, taggedInfluencers[filterTag])
  }

  const filterByName = (influencers, searchString) => {
    function escapeRegExp(string) {
      return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&') // $& means the whole matched string
    }

    const regex = new RegExp(escapeRegExp(searchString), 'i')
    return influencers.filter((influencer) => regex.test(influencer.fullname))
  }

  let result = _.map(influencers, (influencer) => influencer)

  if (nameFilter.trim() !== '') {
    result = filterByName(result, nameFilter.trim())
  }

  result = _.filter(result, (influencer) => {
    return filteredIds.includes(influencer.id)
  })

  if (excludedIds && excludedIds.length > 0) {
    result = _.filter(result, (influencer) => {
      return !excludedIds.includes(influencer.id)
    })
  }

  if (orderBy) {
    switch (orderBy) {
      case 'alphabetical':
        result = _.orderBy(result, (pi) => pi.fullname, 'asc')
        break
      default:
        break
    }
  }

  if (sortProperty && sortDirection) {
    result = _.orderBy(result, sortProperty, sortDirection)
  }

  return result
}

export default applyFilters
