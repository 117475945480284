import Form from 'react-bootstrap/Form'
import { updateParams } from 'redux/searchSlice'
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/hooks'
import _ from 'lodash'
import { RootState } from 'store'
import { Field } from 'types'

interface BasicSelectProps {
  fields: Field[]
  clickText: string
  afterChange?: () => void
}

const valueIsChoice = (value: any) => {
  return value && !_.isEmpty(value) && value !== '--' && value !== 'Select...'
}

const BasicSelect = ({ fields, clickText, afterChange = () => {} }: BasicSelectProps) => {
  const dispatch = useDispatch()
  const params = useSelector((state: RootState) => state.search.params)

  const param = fields[0].param

  return (
    <div className="col-12 col-md-4 mb-3">
      <div className="basic-select">
        <div className="fw-bold text-uppercase mb-2">{clickText}</div>
        <Form.Select
          className="bg-white"
          onChange={(e: React.ChangeEvent<HTMLSelectElement>) => {
            if (!valueIsChoice(e.target.value)) {
              dispatch(updateParams({ [param]: null }))
              return
            }
            dispatch(updateParams({ [param]: e.target.value }))
            afterChange()
          }}
          aria-label="Default select example"
          value={params[param] || '--'}
        >
          {fields[0]?.groups?.map(({ label, value }, index) => {
            return (
              <option value={value} key={`highlight-chooser-${index}`}>
                {label}
              </option>
            )
          })}
        </Form.Select>
      </div>
    </div>
  )
}

export default BasicSelect
