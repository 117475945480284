import allSheetsReducer from './redux/allSheetsSlice'
import categorySlice from './redux/categorySlice'
import collectionsSlice from './redux/collectionsSlice'
import { configureStore } from '@reduxjs/toolkit'
import influencerReducer from './redux/influencerSlice'
import searchFilterReducer from './redux/searchFiltersSlice'
import sheetsReducer from './redux/sheetsSlice'
import socialsReducer from './redux/socialsSlice'
import tagsReducer from './redux/tagsSlice'
import userReducer from './redux/userSlice'
import userSheetsReducer from './redux/userSheetSlice'
import analyticsSlice from 'redux/analyticsSlice'
import teamSlice from 'redux/teamSlice'
import conversationsReducer from 'redux/conversationSlice'
import influencerLikesSlice from 'redux/influencerLikesSlice'
import modalsSlice from 'redux/modalsSlice'
import castingCallSlice from 'redux/castingCallsSlice'
import newSearchSlice from 'redux/searchSlice'

const store = configureStore({
  reducer: {
    user: userReducer,
    influencers: influencerReducer,
    sheets: sheetsReducer,
    userSheet: userSheetsReducer,
    allSheets: allSheetsReducer,
    tags: tagsReducer,
    categories: categorySlice,
    searchFilters: searchFilterReducer,
    collections: collectionsSlice,
    socials: socialsReducer,
    analytics: analyticsSlice,
    team: teamSlice,
    conversations: conversationsReducer,
    influencerLikes: influencerLikesSlice,
    modals: modalsSlice,
    castingCalls: castingCallSlice,
    search: newSearchSlice,
  },
})

export default store

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
