import { Link } from 'react-router-dom'

/**
 * Generates an array representing a pagination scheme for a set of pages.
 *
 * Given the current page and the total number of pages, this function will
 * return an array representing which page numbers to show in a pagination component.
 *
 * The returned array will always contain the first and last page.
 * If the total number of pages is less than or equal to 5, it will simply contain all page numbers.
 * If the total number of pages is more than 5, it will contain the current page, up to two pages before, and up to two pages after the current page.
 * If necessary, it will include '...' as a stand-in for a range of pages not shown.
 *
 * @param currentPage The current active page.
 * @param totalPages The total number of pages.
 * @returns An array of numbers and/or '...', representing which pages to show in the pagination component.
 */
function generatePaginationArray(currentPage, totalPages) {
  let pages = []

  if (totalPages <= 5) {
    for (let i = 1; i <= totalPages; i++) {
      pages.push(i)
    }
  } else {
    // Push the first page
    pages.push(1)

    if (currentPage > 3) {
      pages.push('...')
    }

    // Push up to two pages before the current page
    // if (currentPage - 2 > 1) {
    //   pages.push(currentPage - 2)
    // }
    // Push the page before the current page
    if (currentPage - 1 > 1) {
      pages.push(currentPage - 1)
    }

    // Push the current page
    if (currentPage > 1) {
      pages.push(currentPage)
    }

    // Push the page after the current page
    if (currentPage + 1 < totalPages) {
      pages.push(currentPage + 1)
    }
    // Push two pages after the current page
    // if (currentPage + 2 < totalPages) {
    //   pages.push(currentPage + 2)
    // }

    if (currentPage < totalPages - 2) {
      pages.push('...')
    }

    // Push the last page
    if (currentPage < totalPages) {
      pages.push(totalPages)
    }
  }

  return pages
}

const PageMenu = ({ currentPage, totalPages, baseUrl }) => {
  const pageArray = generatePaginationArray(currentPage, totalPages)

  return (
    <nav className="d-flex justify-content-center" aria-label="Page navigation">
      <ul className="pagination">
        <li className={`page-item ${currentPage > 1 ? '' : 'disabled'}`}>
          {currentPage > 1 ? (
            <Link
              className="page-link"
              to={`${baseUrl}?page=${currentPage - 1}`}
              aria-label="Previous"
            >
              <span aria-hidden="true">&laquo;</span>
            </Link>
          ) : (
            <p className="page-link" aria-label="Previous">
              <span aria-hidden="true">&laquo;</span>
            </p>
          )}
        </li>
        {pageArray.map((v, i) => {
          return (
            <li className={`page-item ${currentPage === v ? 'active' : ''}`} key={`page-${i}`}>
              {v === '...' ? (
                <p className="page-link disabled">{v}</p>
              ) : (
                <Link className="page-link" to={`${baseUrl}?page=${v}`}>
                  {v}
                </Link>
              )}
            </li>
          )
        })}
        <li className={`page-item ${currentPage < totalPages ? '' : 'disabled'}`}>
          {currentPage < totalPages ? (
            <Link
              className="page-link"
              to={`${baseUrl}?page=${currentPage + 1}`}
              aria-label="Next"
            >
              <span aria-hidden="true">&raquo;</span>
            </Link>
          ) : (
            <p className="page-link" aria-label="Next">
              <span aria-hidden="true">&raquo;</span>
            </p>
          )}
        </li>
      </ul>
    </nav>
  )
}

export default PageMenu
