import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

function withSearchOnlyRedirect(WrappedComponent) {
  return function SearchOnlyRedirect(props) {
    const user = useSelector((state) => state.user)

    useEffect(() => {
      if (user.authed && user.search_only && !user.is_admin) {
        window.location.href = '/search'
      }
    }, [user.search_only])

    return <WrappedComponent {...props} />
  }
}

export default withSearchOnlyRedirect
