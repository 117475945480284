import { Link, useLocation, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState, useMemo } from 'react'
import { setSelectedInfluencer, addTags } from 'redux/tagsSlice'
import { setTags } from 'redux/influencerSlice'
import { selectTeamSettings } from 'redux/teamSlice'
import { Watch } from 'react-loader-spinner'

import _, { set } from 'lodash'
import axios from 'axios'
import Select from 'react-select'
import TalentRosterTable from 'TalentRosterTable'
import { joinClassNames } from 'utils'

const RenderTagList = ({ masterTags, tags, handleClick }) => {
  return (
    <div className="mt-2">
      {tags.map((t, i) => {
        return (
          <div
            key={`tag-${i}`}
            data-purpose="add-tag"
            className={joinClassNames([
              'tag selectable badge rounded-pill',
              masterTags.includes(t) ? 'bg-info' : 'bg-secondary',
              'text-white my-1 py-2',
              'm-2',
            ])}
            onClick={() => {
              handleClick(t)
            }}
          >
            {t}
          </div>
        )
      })}
    </div>
  )
}

export default function TagHelper() {
  const dispatch = useDispatch()

  const [requestStatus, setRequestStatus] = useState('new')
  const [error, setError] = useState(null)

  const [suggestedTags, setSuggestedTags] = useState([])
  const masterTags = useSelector((state) => state.tags.masterTags)

  const getTagSuggestions = async (influencer) => {
    let description = `General: ${influencer?.description}`

    if (influencer?.custom_description) {
      description += `\nCustom: ${influencer?.custom_description}\n`
    }

    setRequestStatus('loading')

    try {
      const response = await axios.post('/api/tag-helper', {
        description,
      })
      setSuggestedTags(response.data.tags)
      setRequestStatus('idle')
    } catch (error) {
      setError(error.response?.data?.error || 'Something went wrong.')
      setRequestStatus('idle')
    }
  }

  const influencers = useSelector((state) => state.influencers.entities)
  const socials = useSelector((state) => state.socials.entities)
  const teamSettings = useSelector(selectTeamSettings)

  const selectedInfluencerId = useSelector((state) => state.tags.selectedInfluencer)

  const saveInfluencerTags = async (tags = [], influencer_id) => {
    await axios.post('/api/user/influencers/tags', {
      influencer_id: influencer_id,
      tags,
    })
    dispatch(
      addTags({
        tags,
        influencer_id: influencer_id,
      }),
    )
    return
  }

  const influencerOptions = _.map(influencers, (influencer) => {
    return { value: influencer.id, label: influencer.fullname }
  })

  const handleInputChange = (selectedOption) => {
    dispatch(setSelectedInfluencer(selectedOption.value))
    setSuggestedTags([])
    setRequestStatus('new')

    const newInfluencer = influencers[selectedOption.value]
    if (newInfluencer) {
      getTagSuggestions(newInfluencer)
    }
  }

  const selectedInfluencer = influencers[selectedInfluencerId]

  const selectedOption = selectedInfluencer
    ? _.find(influencerOptions, (option) => option.value === selectedInfluencer.id)
    : {
        value: null,
        label: 'Select Creator',
      }

  const displayedInfluencers = useMemo(() => {
    if (_.isEmpty(influencers) || !selectedInfluencerId) return []
    let influencersToShow = [influencers[selectedInfluencerId]]

    return influencersToShow
  }, [influencers, selectedInfluencerId])

  const handleAddTag = async (tag) => {
    let newTags = selectedInfluencer?.tags ? [...selectedInfluencer.tags] : []
    newTags = _.uniq([...newTags, tag])
    await saveInfluencerTags(newTags, selectedInfluencerId)
    dispatch(setTags({ tags: newTags, influencer: selectedInfluencerId }))
  }

  const hasTag = (tag, influencer) => {
    return influencer.tags?.includes(tag)
  }

  const suggestionsFromMasterTags = suggestedTags.filter((t) => {
    return masterTags.includes(t) && !hasTag(t, selectedInfluencer)
  })

  const otherSuggestions = suggestedTags.filter((t) => {
    return !masterTags.includes(t) && !hasTag(t, selectedInfluencer)
  })

  return (
    <div className="container-fluid">
      <div className="container mt-3">
        <h1 className="my-3 text-center">Tagging Assistant</h1>
        <div
          style={{
            fontSize: '1.1rem',
          }}
        >
          <p>Tags are used to help categorize creators and make them easier for brands to find.</p>
          <p>
            Select an creator from the dropdown below to get a suggested list of tags based on the
            creator's description.
          </p>
          <p className="text-danger">
            Note: This is an experimental feature, we welcome your feedback.
          </p>
        </div>

        <form
          id="influencer-form"
          className="mb-3"
          onSubmit={(e) => {
            e.preventDefault()
            console.log('send form')
          }}
        >
          <div className="d-flex flex-md-row">
            <div className="w-100">
              <Select
                options={influencerOptions}
                value={selectedOption}
                isMulti={false}
                placeholder={'Select Influencer'}
                isClearable={true}
                onChange={handleInputChange}
              />
            </div>
          </div>
        </form>
        <div className="row">
          <div className="col-12 my-3">
            <div style={{ height: '38px' }}>
              {requestStatus === 'loading' && (
                <Watch
                  visible={true}
                  height="50"
                  width="50"
                  radius="48"
                  color="#0056ee"
                  ariaLabel="watch-loading"
                />
              )}
            </div>
          </div>
        </div>
      </div>

      <hr />
      <div className="container">
        <div className="row">
          {error && (
            <div className="col-12">
              <p className="text-center">There was an error generating suggestions: {error}</p>
            </div>
          )}
          {requestStatus === 'idle' && suggestedTags.length === 0 && (
            <div className="col-12">
              <p className="text-center">
                It seems that there are no tags to suggest for this creator. Please try another
                creator.
              </p>
            </div>
          )}
          {suggestedTags.length > 0 && (
            <div className="col-12">
              <div className="row">
                <p
                  style={{
                    fontSize: '1.15rem',
                  }}
                  className="mb-3"
                >
                  Click on a tag to add it. These suggestions are generated by a large language
                  model based on the provided description, so mistakes are possible.{' '}
                </p>

                <div className="col-12 col-md-6">
                  <div className="card shadow-sm">
                    <div className="card-header">
                      <h4 className="text-center">Suggested Commonly-Used Tags</h4>
                    </div>
                    <div className="card-body">
                      {!_.isEmpty(suggestionsFromMasterTags) && (
                        <RenderTagList
                          masterTags={masterTags}
                          tags={suggestionsFromMasterTags}
                          handleClick={handleAddTag}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-md-6">
                  <div className="card shadow-sm">
                    <div className="card-header">
                      <h4 className="text-center">More Suggestions</h4>
                    </div>

                    <div className="card-body">
                      {!_.isEmpty(otherSuggestions) && (
                        <RenderTagList
                          masterTags={masterTags}
                          tags={otherSuggestions}
                          handleClick={handleAddTag}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <hr />
      <div className="d-flex justify-content-between mt-3">
        <TalentRosterTable
          displayedInfluencers={displayedInfluencers}
          socials={socials}
          settings={teamSettings}
        />
      </div>
    </div>
  )
}
