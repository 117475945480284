import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/hooks'
import millify from 'millify'
import FormPopover from './FormPopover'
import HighlightChooser from './HighlightChooser'
import BasicSelect from './BasicSelect'
import { updateParams, performSearch, resetParams, setSelectedTags } from 'redux/searchSlice'
import { getSocialName } from 'utils'
import KeywordSearch from './TagSearch'
import _ from 'lodash'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Popover from 'react-bootstrap/Popover'
import Tooltip from 'react-bootstrap/Tooltip'
import { useParams } from 'react-router-dom'
import { RootState } from 'store'
import { Field } from 'types'

interface PopoverItem {
  clickText: string
  popoverId: string
  description: string
  inputStyle?: 'basic-select' | 'highlight-chooser'
  fields: Field[]
  required_test: (values: any) => boolean
  describe_when_set: (values: any) => string | undefined
  processChoice?: (value: string, params: any) => Record<string, any>
  valueIsChosen?: (value: string, params: any) => boolean
  choiceRequired?: boolean
}

const valueIsChoice = (value: any) => {
  return value && !_.isEmpty(value) && value !== '--'
}

const SearchArea = (props: any) => {
  const dispatch = useDispatch()

  const params = useSelector((state: RootState) => state.search.params)
  const urlParams = useParams<{ team_id?: string }>()

  const popChoices = [
    { label: '--', value: '' },
    ...[10000, 25000, 50000, 100000, 250000, 500000, 1000000, 3000000].map((n) => {
      return { value: n.toString(), label: millify(n) }
    }),
  ]

  const ageGroupOptions = [
    { label: '--', value: '' },
    { label: '13-17', value: '13-17' },
    { label: '18-24', value: '18-24' },
    { label: '25-34', value: '25-34' },
    { label: '35-44', value: '35-44' },
    { label: '45-54', value: '45-54' },
    { label: '55-64', value: '55-64' },
    { label: '65+', value: '65+' },
  ]

  const minAgeChoices = [
    { label: '--', value: '' },
    { label: '13', value: '13' },
    { label: '18', value: '18' },
    { label: '25', value: '25' },
    { label: '35', value: '35' },
    { label: '45', value: '45' },
    { label: '55', value: '55' },
    { label: '65', value: '65' },
  ]

  const maxAgeChoices = [
    { label: '--', value: '' },
    { label: '17', value: '17' },
    { label: '24', value: '24' },
    { label: '34', value: '34' },
    { label: '44', value: '44' },
    { label: '54', value: '54' },
    { label: '64', value: '64' },
  ]

  const percentageOptions = [
    { label: 'Select...', value: '' },
    { label: '≥ 10%', value: '10' },
    { label: '≥ 20%', value: '20' },
    { label: '≥ 30%', value: '30' },
    { label: '≥ 40%', value: '40' },
    { label: '≥ 50%', value: '50' },
    { label: '≥ 60%', value: '60' },
    { label: '≥ 70%', value: '70' },
    { label: '≥ 80%', value: '80' },
    { label: '≥ 90%', value: '90' },
  ]

  const minEngagementRateOptions = [
    { label: 'Select...', value: '' },
    { label: '≥ 1%', value: '1' },
    { label: '≥ 2%', value: '2' },
    { label: '≥ 3%', value: '3' },
    { label: '≥ 5%', value: '5' },
    { label: '≥ 10%', value: '10' },
  ]

  const popoverItems: Record<string, PopoverItem> = {
    followers: {
      clickText: 'Followers',
      popoverId: 'followers-popover',
      description: 'Followers',
      fields: [
        {
          param: 'followers_min',
          label: 'From...',
          type: 'group-select',
          groups: popChoices,
        },
        {
          param: 'followers_max',
          label: 'To...',
          type: 'group-select',
          groups: popChoices,
        },
      ],
      required_test: (values) => {
        return valueIsChoice(values.followers_min) || valueIsChoice(values.followers_max)
      },
      describe_when_set: (values) => {
        let basicDescription = 'Followers'
        basicDescription = ''
        if (values.followers_min && values.followers_max) {
          return `${basicDescription} ${millify(values.followers_min)} - ${millify(
            values.followers_max,
          )}`
        }
        if (values.followers_min) {
          return `${basicDescription} ≥ ${millify(values.followers_min)}`
        }
        if (values.followers_max) {
          return `${basicDescription} ≤ ${millify(values.followers_max)}`
        }
      },
    },
    ageGroup: {
      clickText: 'Audience Age Group',
      popoverId: 'age-group-popover',
      description: 'Target Age Group',
      fields: [
        {
          param: 'audience_age_min',
          label: 'From...',
          type: 'group-select',
          groups: minAgeChoices.filter((item) => {
            if (item.value === '') return true
            if (valueIsChoice(params.audience_age_max)) {
              return Number(item.value) <= Number(params.audience_age_max)
            }
            return true
          }),
        },
        {
          param: 'audience_age_max',
          label: 'To...',
          type: 'group-select',
          groups: maxAgeChoices.filter((item) => {
            if (item.value === '') return true
            if (valueIsChoice(params.audience_age_min)) {
              return Number(item.value) >= Number(params.audience_age_min)
            }
            return true
          }),
        },
        // {
        //   param: 'audience_age_range',
        //   label: 'Age Group',
        //   type: 'group-select',
        //   groups: ageGroupOptions,
        // },
        {
          param: 'min_age_share',
          label: 'Minimum Share',
          type: 'group-select',
          groups: percentageOptions,
        },
      ],
      required_test: (values) => {
        return (
          (valueIsChoice(values.audience_age_min) || valueIsChoice(values.audience_age_max)) &&
          valueIsChoice(values.min_age_share)
        )
        // return values.audience_age_range && values.min_age_share
      },
      describe_when_set: (values) => {
        let basicDescription = 'Ages'
        if (values.audience_age_min && values.min_age_share) {
          return `${basicDescription} ${values.audience_age_min}-${values.audience_age_max} with ≥ ${values.min_age_share}%`
        }
      },
    },
    engagementRate: {
      clickText: 'Engagement Rate',
      popoverId: 'engagement-rate-popover',
      description: 'Engagement Rate',
      inputStyle: 'basic-select',
      fields: [
        {
          param: 'engagement_rate_min',
          label: 'From...',
          type: 'group-select',
          groups: minEngagementRateOptions,
        },
        // {
        //   param: 'engagement_rate_max',
        //   label: 'To...',
        //   type: 'group-select',
        //   groups: engagementRateOptions,
        // },
      ],
      required_test: (values) => {
        return (
          valueIsChoice(values.engagement_rate_min) || valueIsChoice(values.engagement_rate_max)
        )
      },
      describe_when_set: (values) => {
        let basicDescription = 'Engagement Rate'
        if (values.engagement_rate_min && values.engagement_rate_max) {
          return `${basicDescription} ${values.engagement_rate_min}% - ${values.engagement_rate_max}%`
        }
        if (values.engagement_rate_min) {
          return `${basicDescription} ≥ ${values.engagement_rate_min}%`
        }
        if (values.engagement_rate_max) {
          return `${basicDescription} ≤ ${values.engagement_rate_max}%`
        }
      },
    },
    talentGender: {
      clickText: 'Talent Gender',
      popoverId: 'talent-gender-popover',
      description: 'Talent Gender',
      inputStyle: 'highlight-chooser',
      fields: [
        {
          param: 'gender',
          label: 'Gender',
          type: 'group-select',
          groups: [
            { label: '--', value: '' },
            { label: 'Male', value: 'MALE' },
            { label: 'Female', value: 'FEMALE' },
          ],
        },
      ],
      required_test: (values) => {
        return valueIsChoice(values.gender)
      },
      describe_when_set: (values) => {
        let basicDescription = 'Gender'
        if (values.gender) {
          return `${basicDescription}: ${values.gender[0]}${values.gender.slice(1).toLowerCase()}`
        }
      },
      processChoice: (value, params) => {
        if (params.gender === value) {
          return {
            gender: null,
          }
        }
        if (value === 'MALE') {
          return {
            gender: 'MALE',
          }
        }
        if (value === 'FEMALE') {
          return {
            gender: 'FEMALE',
          }
        }
        return {
          gender: null,
        }
      },
      valueIsChosen: (value, params) => {
        return params.gender === value
      },
    },
    talentAge: {
      clickText: 'Talent Age',
      popoverId: 'talent-age-popover',
      description: 'Talent Age',
      fields: [
        {
          param: 'age_minimum',
          label: 'From...',
          type: 'group-select',
          groups: [
            { label: '--', value: '' },
            { label: '18', value: '18' },
            { label: '25', value: '25' },
            { label: '35', value: '35' },
            { label: '45', value: '45' },
            { label: '55', value: '55' },
            { label: '65', value: '65' },
          ].filter((item) => {
            if (item.value === '') return true
            if (params.age_maximum) {
              return Number(item.value) <= Number(params.age_maximum)
            }
            return true
          }),
        },
        {
          param: 'age_maximum',
          label: 'To...',
          type: 'group-select',
          groups: [
            { label: '--', value: '' },
            { label: '17', value: '17' },
            { label: '24', value: '24' },
            { label: '34', value: '34' },
            { label: '44', value: '44' },
            { label: '54', value: '54' },
            { label: '64', value: '64' },
          ].filter((item) => {
            if (item.value === '') return true
            if (params.age_minimum) {
              return Number(item.value) >= Number(params.age_minimum)
            }
            return true
          }),
        },
      ],
      required_test: (values) => {
        return valueIsChoice(values.age_minimum) || valueIsChoice(values.age_maximum)
      },
      describe_when_set: (values) => {
        let basicDescription = 'Talent Age'
        basicDescription = ''
        if (values.age_minimum && values.age_maximum) {
          return `${basicDescription} ${values.age_minimum} - ${values.age_maximum}`
        }
        if (values.age_minimum) {
          return `${basicDescription} ≥ ${values.age_minimum}`
        }
        if (values.age_maximum) {
          return `${basicDescription} ≤ ${values.age_maximum}`
        }
      },
    },
    // Popover for 'Average Views', ranging from 5k to 1M
    averageViews: {
      clickText: 'Average Views',
      popoverId: 'avg-views-popover',
      description: 'Average Views',
      fields: [
        {
          param: 'avg_reels_plays_min',
          label: 'From...',
          type: 'group-select',
          groups: [
            { label: '--', value: '--' },
            ...[5000, 10000, 25000, 50000, 100000, 250000, 500000, 1000000].map((n) => {
              return { value: n.toString(), label: millify(n) }
            }),
          ],
        },
        {
          param: 'avg_reels_plays_max',
          label: 'To...',
          type: 'group-select',
          groups: [
            { label: '--', value: '--' },
            ...[5000, 10000, 25000, 50000, 100000, 250000, 500000, 1000000].map((n) => {
              return { value: n.toString(), label: millify(n) }
            }),
          ],
        },
      ],
      required_test: (values) => {
        return (
          valueIsChoice(values.avg_reels_plays_min) || valueIsChoice(values.avg_reels_plays_max)
        )
      },
      describe_when_set: (values) => {
        let basicDescription = 'Average Views'
        basicDescription = ''
        if (values.avg_reels_plays_min && values.avg_reels_plays_max) {
          return `${basicDescription} ${millify(values.avg_reels_plays_min)} - ${millify(
            values.avg_reels_plays_max,
          )}`
        }
        if (values.avg_reels_plays_min) {
          return `${basicDescription} ≥ ${millify(values.avg_reels_plays_min)}`
        }
        if (values.avg_reels_plays_max) {
          return `${basicDescription} ≤ ${millify(values.avg_reels_plays_max)}`
        }
      },
    },
    // Popover for us_audience_share_min, from 10% to 80%
    usAudienceShare: {
      clickText: 'US Audience Share',
      popoverId: 'us-audience-share-popover',
      description: 'US Audience Share',
      inputStyle: 'basic-select',
      fields: [
        {
          param: 'us_audience_share_min',
          label: 'Minimum',
          type: 'group-select',
          groups: percentageOptions,
        },
      ],
      required_test: (values) => {
        return valueIsChoice(values.us_audience_share_min)
      },
      describe_when_set: (values) => {
        let basicDescription = 'US Audience Share'
        if (values.us_audience_share_min) {
          return `${basicDescription} ≥ ${values.us_audience_share_min}%`
        }
      },
    },
    // Popover for platform, Instagram, TikTok, YouTube
    platform: {
      clickText: 'Platform',
      popoverId: 'platform-popover',
      description: 'Platform',
      inputStyle: 'highlight-chooser',
      fields: [
        {
          param: 'platform',
          label: 'Platform',
          type: 'group-select',
          groups: [
            { label: 'Instagram', value: 'instagram' },
            { label: 'TikTok', value: 'tiktok' },
            { label: 'YouTube', value: 'youtube' },
          ],
        },
      ],
      required_test: (values) => {
        return valueIsChoice(values.platform)
      },
      describe_when_set: (values) => {
        let basicDescription = 'Platform'
        if (values.platform) {
          return `${basicDescription} ${getSocialName(values.platform)}`
        }
      },
      choiceRequired: true,
    },
    audienceGender: {
      clickText: 'Audience Gender',
      popoverId: 'audience-gender-popover',
      description: 'Audience Gender',
      inputStyle: 'highlight-chooser',
      fields: [
        {
          param: 'gender',
          label: 'Gender',
          type: 'group-select',
          groups: [
            { label: '--', value: '' },
            {
              label: (
                <span>
                  Skews Male
                  <OverlayTrigger
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-skews-male`}>Audience {'>70%'} Male</Tooltip>}
                  >
                    <span style={{ minWidth: '8px', minHeight: '8px' }}>
                      <AiOutlineInfoCircle className="ms-1" />
                    </span>
                  </OverlayTrigger>
                </span>
              ),
              value: 'MALE',
            },
            {
              label: (
                <span>
                  Skews Female
                  <OverlayTrigger
                    placement={'right'}
                    overlay={<Tooltip id={`tooltip-skews-male`}>Audience {'>70%'} Female</Tooltip>}
                  >
                    <span style={{ minWidth: '8px', minHeight: '8px' }}>
                      <AiOutlineInfoCircle className="ms-1" />
                    </span>
                  </OverlayTrigger>
                </span>
              ),
              value: 'FEMALE',
            },
          ],
        },
      ],
      required_test: (values) => {
        return valueIsChoice(values)
      },
      describe_when_set: (values) => {
        let basicDescription = 'Gender'
        if (values.gender) {
          return `${basicDescription}: ${values.gender[0]}${values.gender.slice(1).toLowerCase()}`
        }
      },
      processChoice: (value, params) => {
        if (params.target_gender === value) {
          return {
            target_gender: null,
            target_min_gender_share: null,
          }
        }
        if (value === 'MALE') {
          return {
            target_gender: 'MALE',
            target_min_gender_share: 70,
          }
        }
        if (value === 'FEMALE') {
          return {
            target_gender: 'FEMALE',
            target_min_gender_share: 70,
          }
        }
        return {
          target_gender: null,
          target_min_gender_share: null,
        }
      },
      valueIsChosen: (value, params) => {
        return params.target_gender === value
      },
    },
  }

  const popovers = [
    popoverItems.platform,
    popoverItems.followers,
    popoverItems.usAudienceShare,
    popoverItems.ageGroup,
    popoverItems.audienceGender,
    popoverItems.engagementRate,
    popoverItems.averageViews,
    popoverItems.talentGender,
    popoverItems.talentAge,
  ]

  const searchWithParams = () => {
    dispatch(performSearch({}))
  }

  return (
    <div className="container my-2 search-area">
      <div className="row justify-content-center">
        {/* <div className="col-12 mb-3">
          <button
            onClick={() => {
              searchWithParams();
            }}
            className="search-button"
          >
            Search
          </button>
        </div> */}
      </div>

      <div className="row">
        <div className="col-12">
          <KeywordSearch searchWithParams={searchWithParams} />
        </div>
        {/* <div className="col-12">
          <KeywordSearch searchWithParams={searchWithParams} keywordGroup={'State'} />
        </div>
        <div className="col-12">
          <KeywordSearch searchWithParams={searchWithParams} keywordGroup={'City'} />
        </div> */}
      </div>

      <form
        onSubmit={(e) => {
          e.preventDefault()
          dispatch(performSearch({}))
        }}
      >
        <div className="row">
          {popovers.map(
            (
              {
                clickText,
                popoverId,
                description,
                fields,
                required_test,
                inputStyle,
                describe_when_set = () => {
                  return ''
                },
                processChoice,
                valueIsChosen,
                choiceRequired,
              },
              i,
            ) => {
              const buttonText = required_test(params) ? describe_when_set(params) : 'Select...'

              if (inputStyle === 'basic-select') {
                return (
                  <BasicSelect
                    key={`basic-select-${i}`}
                    clickText={clickText}
                    fields={fields}
                    afterChange={() => {
                      searchWithParams()
                    }}
                  />
                )
              }

              if (inputStyle === 'highlight-chooser') {
                return (
                  <HighlightChooser
                    key={`highlight-chooser-${i}`}
                    choiceRequired={choiceRequired}
                    clickText={clickText}
                    fields={fields}
                    processChoice={processChoice}
                    valueIsChosen={valueIsChosen}
                    afterChange={() => {
                      searchWithParams()
                    }}
                  />
                )
              }

              const obj: { [key: string]: any } = {}

              return (
                <div className="col-12 col-md-4 mb-3" key={`form-popover-${i}`}>
                  <FormPopover
                    choiceText={buttonText}
                    clickText={clickText}
                    popoverId={popoverId}
                    description={description}
                    fields={fields}
                    required_test={required_test}
                    handlePopoverConfirm={(values: any) => {
                      // check if all required params are present
                      if (required_test && !required_test(values)) {
                        dispatch(
                          updateParams(
                            fields.reduce((acc, field) => {
                              acc[field.param] = null
                              return acc
                            }, obj),
                          ),
                        )
                        return
                      } else {
                        dispatch(updateParams(values))
                        searchWithParams()
                      }
                    }}
                  />
                </div>
              )
            },
          )}
        </div>
        <div className="row">
          <div className="col-12 col-md-6">
            <input
              value={params.name_search || ''}
              onChange={(e) => {
                dispatch(updateParams({ name_search: e.target.value }))
              }}
              className="form-control bg-white mb-3"
              placeholder="Name search..."
            />
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-12">
            <button type="submit" className="search-button d-none">
              Search
            </button>
          </div>
          <div className="col-12">
            <button
              style={{
                borderRadius: '20px',
              }}
              className="btn btn-lg btn-outline-primary my-3 w-100"
              onClick={() => {
                dispatch(resetParams())
                dispatch(setSelectedTags([]))
              }}
            >
              Clear Filters
            </button>
          </div>
        </div>
      </form>
    </div>
  )
}

export default SearchArea
