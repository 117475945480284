import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Modal, Button, Form } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { fetchUserStatus, setUser } from 'redux/userSlice'

const SigninForm = ({ setShowSignin }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()

  const handleSignin = async (signInData) => {
    const { data } = await axios.post('/api/user/account/signin', {
      email: signInData.email,
      password: signInData.password,
    })
    dispatch(setUser(data))
  }

  useEffect(() => {
    if (user.authed) {
      setShowSignin(false)
    }
  }, [user.authed])

  return (
    <div className="container p-3">
      <div className="row d-flex justify-content-center">
        <div className="col-12 col-md-9 d-flex justify-content-center align-items-center welcome-box">
          <div>
            <div className="logo-container text-center mb-2">
              <img
                src="https://us-east-1.linodeobjects.com/tpp-content/logos/irFa-tpp_logo.jpeg"
                alt="Company Logo"
                className="company-logo"
              />
            </div>
            <Form onSubmit={handleSubmit(handleSignin)}>
              <Form.Group className="mb-3">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="text" {...register('email', { required: true })} />
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Password</Form.Label>
                <Form.Control type="password" {...register('password')} />
              </Form.Group>
              <Button variant="primary" type="submit">
                Sign in
              </Button>
            </Form>
            <p className="mt-3">
              No account?{' '}
              <button onClick={() => setShowSignin(false)} className="btn btn-link">
                Sign up
              </button>{' '}
            </p>
            <div className="mt-4">
              <p>
                Forgot your password? Reset it <Link to="/forgot-password">here</Link>.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

const SignupModal = ({ showModal, handleClose }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const handleSignup = (data) => {
    // You can handle the signup logic here, like sending the data to your API
    console.log('Name:', data.name)
    console.log('Email:', data.email)
    console.log('Affiliation:', data.affiliation)
    // handleClose()
    axios
      .post('/api/account-registration', data)
      .then((res) => {
        console.log(res.data)
        dispatch(fetchUserStatus())
      })
      .catch((err) => {
        console.log(err)
        if (err.response.data?.error === 'EMAIL_EXISTS') {
          setSubmitError('This email is already registered. Please sign in.')
        }
      })
  }

  const [showSignin, setShowSignin] = useState(false)
  const [submitError, setSubmitError] = useState(null)

  const user = useSelector((state) => state.user)
  const dispatch = useDispatch()

  useEffect(() => {
    if (user.authed) {
      handleClose()
    }
  }, [user.authed])

  return (
    <Modal size="lg" show={showModal} onHide={handleClose}>
      <Modal.Body style={{ padding: 0 }}>
        {showSignin ? (
          <SigninForm setShowSignin={setShowSignin} />
        ) : (
          <div className="container p-3">
            <div className="row d-flex justify-content-center">
              <div className="col-12 col-md-9 d-flex justify-content-center align-items-center welcome-box">
                <div>
                  <div className="logo-container text-center mb-2">
                    <img
                      src="https://us-east-1.linodeobjects.com/tpp-content/logos/irFa-tpp_logo.jpeg"
                      alt="Company Logo"
                      className="company-logo"
                    />
                  </div>

                  <h2 className="mb-3 text-center">Show your interest</h2>
                  <p>
                    We ask for your name, email address, and affiliation so that you can easily
                    alert the talent manager to let them know you're interested in working with
                    this influencer.
                  </p>
                  <Form onSubmit={handleSubmit(handleSignup)}>
                    <Form.Group className="mb-3">
                      <Form.Label>Name</Form.Label>
                      <Form.Control type="text" {...register('name', { required: true })} />
                      {errors.name && <span>Name is required</span>}
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control type="email" {...register('email', { required: true })} />
                      {errors.email && <span>Email is required</span>}
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Agency</Form.Label>
                      <Form.Control type="text" {...register('affiliation')} />
                    </Form.Group>
                    {submitError && <div className="error">{submitError}</div>}
                    <Button variant="primary" type="submit">
                      Register
                    </Button>
                  </Form>
                  <p className="mt-3">
                    Already have an account?{' '}
                    <button onClick={() => setShowSignin(true)} className="btn btn-link">
                      Sign in
                    </button>{' '}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal.Body>
    </Modal>
  )
}

export default SignupModal
