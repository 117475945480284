import { useNavigate, useParams } from 'react-router-dom'

import axios from 'axios'
import { useState } from 'react'
import PreLogin from 'PreLogin'

function ResetPassword() {
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [error, setError] = useState(null)
  const navigate = useNavigate()
  const params = useParams()

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (password !== passwordConfirm) {
      setError('Passwords do not match')
      return
    }
    if (password.length < 6) {
      setError('Choose a longer password')
      return
    }

    try {
      await axios.post('/api/user/account/reset-password', {
        code: params.resetCode,
        new_password: password,
      })
      navigate('/signin')
    } catch (e) {
      console.log(e)
      setError('Error trying to set a password. Please contact us')
    }
  }

  return (
    <PreLogin>
      <h2 className="mb-5 text-center">Please set a new password for your account</h2>
      <div className="row justify-content-center">
        <div className="col-10 col-sm-6">
          <form className="sign-in-form" onSubmit={handleSubmit}>
            <div className="mb-3">
              <input
                type="password"
                className="form-control"
                placeholder="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value)
                }}
              />
            </div>
            <div className="mb-3">
              <input
                type="password"
                className="form-control"
                placeholder="confirm password"
                value={passwordConfirm}
                onChange={(e) => {
                  setPasswordConfirm(e.target.value)
                }}
              />
            </div>
            <div className="d-flex justify-content-center">
              <input className="btn btn-primary my-3" type="submit" value="Change Password" />
            </div>
          </form>
          <div className="mt-4"></div>
        </div>
        {error && <div className="error">{error}</div>}
      </div>
    </PreLogin>
  )
}

export default ResetPassword
