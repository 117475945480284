import { addMarkdownLineBreaks, joinClassNames } from 'utils'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'

function RenderMarkdown({ markdownText, textColor }) {
  return (
    <ReactMarkdown
      children={addMarkdownLineBreaks(markdownText)}
      remarkPlugins={[remarkGfm]}
      components={{
        a: ({ node, ...props }) => (
          <a
            {...props}
            className={joinClassNames([textColor ? `text-${textColor}` : null])}
            target="_blank"
            rel="noopener noreferrer"
          />
        ),
      }}
    />
  )
}

export default RenderMarkdown
