import { OverlayTrigger, Popover, Button } from 'react-bootstrap'
import { IoInformationCircleSharp } from 'react-icons/io5'

const InfoPopover = ({ title, content }) => (
  <OverlayTrigger
    trigger="click"
    placement="right"
    overlay={
      <Popover id="popover-basic">
        <Popover.Header as="h3">{title}</Popover.Header>
        <Popover.Body>{content}</Popover.Body>
      </Popover>
    }
    rootClose
  >
    <Button variant="link" className="ms-2 info-icon-button">
      <IoInformationCircleSharp />
    </Button>
  </OverlayTrigger>
)

export default InfoPopover
