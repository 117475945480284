import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import _ from 'lodash'
import axios from 'axios'

export const fetchSheetsTypeSheetsSlice = createAsyncThunk(
  'sheets/fetchType',
  async ({ sheet_type }) => {
    const response = await axios.get(`/api/talent-sheets?sheet_type=${sheet_type}`)
    return _.get(response, 'data')
  },
)

const sheetsAdapter = createEntityAdapter()

export const sheetsSlice = createSlice({
  name: 'sheets',
  initialState: sheetsAdapter.getInitialState({
    loading: 'idle',
  }),
  reducers: {
    setSheets: (state, { payload }) => {
      sheetsAdapter.upsertMany(state, payload)
    },
    removeSheet: (state, { payload }) => {
      sheetsAdapter.removeOne(state, payload)
    },
  },
  extraReducers: function (builder) {
    builder.addCase(fetchSheetsTypeSheetsSlice.fulfilled, (state, { payload }) => {
      sheetsAdapter.upsertMany(state, payload)
    })
  },
})

// Action creators are generated for each case reducer function
export const { setSheets, removeSheet } = sheetsSlice.actions

export default sheetsSlice.reducer
