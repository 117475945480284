import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import RegistrationPopup from 'components/RegistrationPopup'

const withRegistrationPopup = (WrappedComponent) => {
  return (props) => {
    const user = useSelector((state) => state.user)
    const [showRegistrationPopup, setShowRegistrationPopup] = useState(false)
    const userEmail = user.email

    useEffect(() => {
      if (
        !user.authed &&
        (props.displaySettings.displayRequireRegistration ||
          props.talentSheet?.access_restricted) &&
        !userEmail
      ) {
        setShowRegistrationPopup(true)
      } else {
        setShowRegistrationPopup(false)
      }
    }, [user, props.displaySettings, userEmail])

    return (
      <>
        <WrappedComponent {...props} />
        <RegistrationPopup
          showModal={showRegistrationPopup}
          handleClose={(authorizeClose) => {
            if (authorizeClose) {
              setShowRegistrationPopup(false)
            }
          }}
          displaySettings={props.displaySettings}
        />
      </>
    )
  }
}

export default withRegistrationPopup
