import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import _ from 'lodash'
import axios from 'axios'

export const getAnalyticsKey = (formValues) => {
  const { sinceDays, onlyUserSheets } = formValues
  return `${sinceDays}-${onlyUserSheets}`
}

export const fetchVisitorAnalytics = createAsyncThunk(
  'analytics/fetchVisitorAnalytics',
  async () => {
    const response = await axios.get('/api/user/analytics-visitors')
    return response.data
  },
)

export const fetchAnalytics = createAsyncThunk(
  'analytics/fetch',
  async ({ sinceDays, onlyUserSheets }, { getState }) => {
    const analyticsKey = getAnalyticsKey({ sinceDays, onlyUserSheets })

    const currentState = getState().analytics
    if (currentState[analyticsKey]) {
      return {
        value: currentState[analyticsKey],
        key: analyticsKey,
        isCached: true,
      }
    }

    const params = {}
    if (sinceDays !== 'all') {
      params.sinceDays = sinceDays
    }
    if (onlyUserSheets) {
      params.onlyUserSheets = onlyUserSheets
    }

    const response = await axios.get('/api/user/analytics', { params })
    return {
      value: response.data,
      key: analyticsKey,
      isCached: false,
    }
  },
)

// export const fetchAnalytics = createAsyncThunk(
//   'analytics/fetch',
//   async ({ sinceDays, onlyUserSheets }) => {
//     const params = {}
//     if (sinceDays !== 'all') {
//       params.sinceDays = sinceDays
//     }
//     if (onlyUserSheets) {
//       params.onlyUserSheets = onlyUserSheets
//     }

//     const analyticsKey = getAnalyticsKey({ sinceDays, onlyUserSheets })

//     const response = await axios.get('/api/user/analytics', { params })
//     return {
//       value: response.data,
//       key: analyticsKey,
//     }
//   },
// )

export const analyticsSlice = createSlice({
  name: 'analytics',
  initialState: {
    loading: false,
    recentVisits: [],
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAnalytics.fulfilled, (state, { payload }) => {
        const { key, value } = payload
        state[key] = value

        state.loading = false
      })
      .addCase(fetchAnalytics.pending, (state, { payload }) => {
        state.loading = true
      })
      .addCase(fetchVisitorAnalytics.fulfilled, (state, { payload }) => {
        state.recentVisits = _.orderBy(payload.recentVisits, 'most_recent_visit', 'desc')
      })
  },
})

export default analyticsSlice.reducer
