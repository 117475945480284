import colors from './styles/_variables.scss'

const age_groups = ['18-24', '25-34', '35-44', '45-64', '65-']

const categories = {
  Skincare: [
    'Hyperpigmentation',
    'Dry Skin',
    'Skincare routine',
    'Product reviews',
    'Beauty Over 40',
    'Bodycare',
    'Anti Aging',
    'Glowing Skin',
    'Dark Spots',
    'Sensative Skin',
    'body positive',
    'botox friendly',
    'clean beauty',
    'postpartum',
    'menopausal skin',
  ],
  Haircare: ['natural haircare', 'organic haircare', 'Hair tips'],
  'Account Type': ['Dermatologist', 'Medical Professional', 'Actress', 'Celebrity', 'Model'],
  Gender: ['Female', 'Male', 'Gender Neutral'],
  Ethnicity: ['African Descent', 'Asian', 'White / Caucasian', 'Hispanic American', 'Mixed'],
  Lifestyle: [
    'Fitness',
    'Travel',
    'Food',
    'Humor',
    'baby',
    'cooking',
    'diy',
    'luxury',
    'graphic designer',
    'health focused',
    'photographer',
    'vegan',
  ],
}

const moduleExports = {
  age_groups,
  categories,
  colors,
  SITE_ROOT: 'https://app.talentpitchpro.com',
  BRAND_LOGO_URL: 'https://us-east-1.linodeobjects.com/tpp-content/logos/irFa-tpp_logo.jpeg',
}

export default moduleExports
