import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'
import axios from 'axios'
import _ from 'lodash'
import { Conversation, Message } from 'types'

interface FetchConversationsResponse {
  conversations: Conversation[]
  messages: Message[]
}

interface FetchOneConversationPayload {
  conversationId: string
}

interface MarkAsReadPayload {
  conversation_id: string
}

interface ArchiveConversationPayload {
  conversationId: string
}

interface SendReplyPayload {
  reply: {
    body: string
    conversation_id: string
  }
}

interface DeleteMessagePayload {
  message_id: string
}

interface FetchConversationsParams {
  only_unread?: boolean
}

export const fetchConversations = createAsyncThunk<
  FetchConversationsResponse,
  FetchConversationsParams
>('messages/fetchConversations', async ({ only_unread = false }) => {
  const response = await axios.get('/api/conversations', {
    params: {
      only_unread,
    },
  })
  return response.data
})

export const fetchOneConversation = createAsyncThunk<
  FetchConversationsResponse,
  FetchOneConversationPayload
>('messages/fetchOneConversation', async ({ conversationId }) => {
  const response = await axios.get(`/api/conversations/${conversationId}`)
  return response.data
})

export const markAsRead = createAsyncThunk<{ id: string }, MarkAsReadPayload>(
  'conversation/markAsRead',
  async ({ conversation_id }) => {
    const response = await axios.post('/api/conversations/mark-read', {
      conversation_id: conversation_id,
    })
    return { id: conversation_id }
  },
)

export const archiveConversation = createAsyncThunk<any, ArchiveConversationPayload>(
  'conversation/archiveConversation',
  async ({ conversationId }) => {
    const response = await axios.post('/api/conversations/messages', {
      action: 'archive',
      conversation_id: conversationId,
    })
    return response.data
  },
)

export const sendReply = createAsyncThunk<any, SendReplyPayload>(
  'conversation/sendReply',
  async ({ reply }) => {
    if (reply.body.trim() === '') {
      console.log('no body')
      return
    }
    const response = await axios.post('/api/conversations/reply', {
      conversation_id: reply.conversation_id,
      content: reply.body.trim(),
    })
    return response.data
  },
)

export const deleteMessage = createAsyncThunk<any, DeleteMessagePayload>(
  'conversation/deleteMessage',
  async ({ message_id }) => {
    const response = await axios.post('/api/conversation/delete-message', {
      message_id: message_id,
    })
    return response.data
  },
)

const messageAdapter = createEntityAdapter<Message>()

const conversationAdapter = createEntityAdapter<Conversation>()

interface ConversationsState {
  conversations: ReturnType<typeof conversationAdapter.getInitialState> & {
    loading: string
    error: any
  }
  messages: ReturnType<typeof messageAdapter.getInitialState> & { loading: string; error: any }
  unread_conversations: string[]
  has_unread_conversation: boolean
  user_acknowledged_notification: boolean
  newReply: {
    conversation_id: string | null
    body: string
  }
  newConversation: {
    title: string
    body: string
    receiver_id: string | null
    influencer_id: string | null
  }
}

const initialState: ConversationsState = {
  conversations: conversationAdapter.getInitialState({ loading: 'idle', error: null }),
  messages: messageAdapter.getInitialState({ loading: 'idle', error: null }),
  unread_conversations: [],
  has_unread_conversation: false,
  user_acknowledged_notification: false,
  newReply: {
    conversation_id: null,
    body: '',
  },
  newConversation: {
    title: '',
    body: '',
    receiver_id: null,
    influencer_id: null,
  },
}

const conversationSlice = createSlice({
  name: 'conversations',
  initialState,
  reducers: {
    disableNotification: (state) => {
      state.has_unread_conversation = false
      state.user_acknowledged_notification = true
    },
    updateReply: (state, { payload }) => {
      state.newReply = {
        ...state.newReply,
        ...payload,
      }
    },
    updateNewConversation: (state, { payload }) => {
      state.newConversation = {
        ...state.newConversation,
        ...payload,
      }
    },
    resetConversation: (state) => {
      state.newConversation = {
        title: '',
        body: '',
        receiver_id: null,
        influencer_id: null,
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConversations.pending, (state) => {
        state.conversations.loading = 'loading'
      })
      .addCase(fetchConversations.fulfilled, (state, { payload }) => {
        state.conversations.loading = 'idle'
        conversationAdapter.upsertMany(state.conversations, payload.conversations)
        messageAdapter.upsertMany(state.messages, payload.messages)

        const unread_conversations = payload.conversations
          .filter((c) => c.has_unread_message)
          .map((c) => {
            return c.id
          })
        state.unread_conversations = unread_conversations
      })
      .addCase(fetchOneConversation.fulfilled, (state, { payload }) => {
        state.conversations.loading = 'idle'
        conversationAdapter.upsertMany(state.conversations, payload.conversations)
        messageAdapter.upsertMany(state.messages, payload.messages)
      })
      .addCase(fetchConversations.rejected, (state, action) => {
        state.conversations.loading = 'idle'
        state.conversations.error = action.error
      })
      .addCase(markAsRead.fulfilled, (state, { payload }) => {
        conversationAdapter.updateOne(state.conversations, {
          id: payload.id,
          changes: {
            has_unread_message: false,
          },
        })

        state.unread_conversations = _.without(state.unread_conversations, payload.id)
      })
      .addCase(archiveConversation.fulfilled, (state, { payload }) => {
        conversationAdapter.removeOne(state.conversations, payload.conversation_id)
      })
  },
})

export const { disableNotification, updateReply, updateNewConversation, resetConversation } =
  conversationSlice.actions

export default conversationSlice.reducer
