import { Link, useNavigate, Navigate } from 'react-router-dom'

import axios from 'axios'
import { setUser, signIn } from './redux/userSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'
import PreLogin from 'PreLogin'

function Login() {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState(null)
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const user = useSelector((state) => state.user)

  if (user.loaded && user.authed) {
    return <Navigate to="/" />
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    try {
      await dispatch(
        signIn({
          email,
          password,
        }),
      ).unwrap()
      navigate('/')
    } catch (e) {
      let errorMessage = e.error || 'Could not sign in'
      setError(errorMessage)
    }
  }

  return (
    <PreLogin>
      <h1 className="mb-3 text-center">Talent Pitch Pro</h1>
      <h3 className="mb-5 text-center">Please sign in</h3>
      <div className="row justify-content-center">
        <div className="col-10 col-sm-6">
          <form className="sign-in-form" onSubmit={handleSubmit}>
            <div className="mb-3">
              <input
                type="text"
                className="form-control"
                autoFocus
                value={email}
                placeholder="email"
                onChange={(e) => {
                  setEmail(e.target.value)
                }}
              />
            </div>
            <div className="mb-3">
              <input
                type="password"
                className="form-control"
                placeholder="password"
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value)
                }}
              />
            </div>
            <div className="d-flex justify-content-center">
              <input className="btn btn-primary sign-in-btn my-3" type="submit" value="Sign In" />
            </div>{' '}
          </form>
          {error && <div className="error">Sign-in not successful</div>}
          <div className="mt-4">
            <p>
              Forgot your password or account not set up yet? Click{' '}
              <Link to="/forgot-password">here</Link>.
            </p>
          </div>
        </div>
      </div>
    </PreLogin>
  )

  return (
    <div className="container mt-4">
      <div className="row justify-content-center mx-1">
        <div className="col-md-10 col-lg-8 signin-main p-5">
          <h1 className="mb-3 text-center">Talent Pitch Pro</h1>
          <h3 className="mb-5 text-center">Please sign in</h3>
          <div className="row justify-content-center">
            <div className="col-10 col-sm-6">
              <form className="sign-in-form" onSubmit={handleSubmit}></form>
              {/* <p>Not registered yet? <Link to="/signup">Sign up</Link></p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Login
