import { Link, useNavigate, useParams } from 'react-router-dom'
import { setInfluencer, setInfluencers } from './redux/influencerSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import Table from 'react-bootstrap/Table'

import _ from 'lodash'
import axios from 'axios'
import { useForm } from 'react-hook-form'
import LoadingContainer from 'components/LoadingContainer'

export default function SheetClicks() {
  const params = useParams()

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: {
      sinceDays: 'all',
    },
  })

  const onSubmit = async (data) => {
    getAnalytics({
      sinceDays: data.sinceDays,
    })
  }

  useEffect(() => {
    handleSubmit(onSubmit)()
  }, [watch('sinceDays')])

  const [analytics, setAnalytics] = useState({})
  const [startDate, setStartDate] = useState(null)

  const getAnalytics = ({ sinceDays, team_id } = {}) => {
    const data = {
      sheet_id: params.sheetId,
    }

    // Not using sinceDays for now
    // if (sinceDays !== 'all') {
    //   data.sinceDays = Number(sinceDays)
    // }

    axios.get('/api/user/analytics/sheet-clicks', { params: data }).then(({ data }) => {
      setAnalytics(data)
    })
  }

  useEffect(() => {
    getAnalytics()
  }, [])

  if (_.isEmpty(analytics)) return <LoadingContainer />

  if (_.isEmpty(analytics.clicks))
    return (
      <div className="container mt-4">
        <p>No profile clicks have been recorded yet.</p>
      </div>
    )

  const total_clicks = _.sumBy(analytics.clicks, 'clicks')

  return (
    <div className="container">
      {/* <h1>Sheet Clicks {startDate && `since ${startDate}`}</h1> */}

      <form
        className="w-50 mb-3"
        onSubmit={(e) => {
          handleSubmit(onSubmit)(e)
        }}
      >
        <button className="btn btn-primary mt-3 d-none" type="submit">
          Submit
        </button>
      </form>
      {/* <hr /> */}

      {analytics.clicks && (
        <>
          <h1 className="my-2">{analytics.clicks[0].sheet_title}</h1>
          <h2>Total Profile Clicks: {total_clicks}</h2>
          <Table striped bordered responsive className="mt-4 w-100">
            <thead>
              <tr>
                <th>Talent Name</th>
                <th scope="col">CLICKS</th>
              </tr>
            </thead>
            <tbody>
              {analytics.clicks &&
                analytics.clicks.map((row) => {
                  return (
                    <tr key={`click-${row.sheet_id}-${row.influencer_id}`}>
                      <td>{row.fullname}</td>
                      <td>{row.clicks}</td>
                    </tr>
                  )
                })}
            </tbody>
          </Table>
        </>
      )}
    </div>
  )
}
