import { Link, useNavigate, Navigate } from 'react-router-dom'

import axios from 'axios'
import { setUser, signIn } from './redux/userSlice'
import { useSelector } from 'react-redux'
import PreLogin from 'PreLogin'

function AccountDisabled() {
  return (
    <PreLogin preventRedirect={true}>
      <h2 className="mb-3 text-center">Account Inactive</h2>
      <p className="text-center">
        Your account is currently inactive. Please contact us to resolve the issue.
      </p>
    </PreLogin>
  )
}

export default AccountDisabled
