import { Navigate } from 'react-router-dom'

import { useSelector } from 'react-redux'

function PreLogin(props) {
  const user = useSelector((state) => state.user)

  if (user.loaded && user.authed && !props.preventRedirect) {
    return <Navigate to="/" />
  }
  return (
    <div className="login-container">
      <div className="container mt-4">
        <div className="row justify-content-center mx-1">
          <div className="col-md-10 col-lg-8 signin-main p-5">
            <div className="logo-container">
              <img
                src="https://us-east-1.linodeobjects.com/tpp-content/logos/irFa-tpp_logo.jpeg"
                alt="Company Logo"
                className="company-logo"
              />
            </div>
            {props.children}
          </div>
        </div>
      </div>
    </div>
  )
}

export default PreLogin
