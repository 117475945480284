import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

import PageMenu from './PageMenu'
import TalentRosterTable from './TalentRosterTable'
import { fetchFavoriteInfluencers } from './redux/userSlice'
import { useNavigate } from 'react-router-dom'
import _ from 'lodash'

export default function SearchFavorites() {
  const pageLimit = 50
  const navigate = useNavigate()
  const influencers = useSelector((state) => state.influencers.entities)
  const socials = useSelector((state) => state.socials.entities)

  const total_influencers = useSelector((state) => state.influencers.total_influencers)
  const user = useSelector((state) => state.user)
  const searchParams = new URLSearchParams(window.location.search)
  const page = Number(searchParams.get('page')) || 1

  const [orderBy, setOrderBy] = useState(null)
  const [displayedInfluencers, setDisplayedInfluencers] = useState([])

  const favorites = useSelector((state) => state.user.favoriteInfluencers)

  useEffect(() => {
    const toBeDisplayed = favorites
      .map((id) => {
        return influencers[id]
      })
      .filter((i) => i)
      .map((influencer) => {
        return {
          ...influencer,
          primary_account_id: influencer.primary_social_id,
          socials: _.keyBy(influencer.socials, 'social_id'),
        }
      })

    // console.log(toBeDisplayed)
    setDisplayedInfluencers(toBeDisplayed)
  }, [favorites, influencers])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [page])

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(fetchFavoriteInfluencers())
  }, [])

  const totalPages = Math.ceil(favorites.length / pageLimit)

  const showPageMenus = totalPages > 1

  return (
    <div className="container mt-2">
      {showPageMenus && (
        <PageMenu currentPage={page} totalPages={totalPages} baseUrl="/favorites" />
      )}

      <TalentRosterTable
        displayedInfluencers={displayedInfluencers}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        profileType={'search'}
        socials={socials}
      />
      {showPageMenus && (
        <PageMenu currentPage={page} totalPages={totalPages} baseUrl="/favorites" />
      )}
    </div>
  )
}
