import { Link, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'

import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import _ from 'lodash'
import axios from 'axios'
import produce from 'immer'
// import { setSheet } from './redux/userSheetSlice'
import { shortenText } from './utils'

function NotesModal({ show, handleClose, editingCell, handleCellInput, campaignData }) {
  if (!editingCell) {
    return null
  }
  const influencer_id = editingCell.split('-')[0]

  return (
    <>
      <Modal
        dialogClassName="w-75"
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Notes</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ height: '15em' }}>
            <form
              onSubmit={(e) => {
                e.preventDefault()
                handleClose()
              }}
            >
              <textarea
                id="modal-notes"
                autoFocus
                type="text"
                className="form-control"
                // placeholder=""
                value={_.get(campaignData, [influencer_id, 'Notes'], '')}
                onChange={(e) => handleCellInput(influencer_id, 'Notes', e.target.value)}
                style={{ height: '14em' }}
              />
              <button type="submit" hidden />
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            data-purpose="set-title"
            onClick={() => {
              handleClose()
            }}
            variant="primary"
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default function CampaignDetails() {
  const navigate = useNavigate()
  const params = useParams()

  const [campaignData, setCampaignData] = useState({})
  const [editingCell, setEditingCell] = useState(null)
  const [saved, setSaved] = useState(null)
  const [error, setError] = useState(null)

  const reduxSheet = useSelector((state) => state.userSheet)
  const talentSheetInfluencers = useSelector((state) => {
    return _.orderBy(state.userSheet.influencers.entities, 'position')
  })
  const [showModal, setShowModal] = useState(false)

  const dispatch = useDispatch()

  const handleCellInput = (influencer_id, column, value) => {
    const nextData = produce(campaignData, (draftState) => {
      draftState[influencer_id] = {
        ...draftState[influencer_id],
      }
      draftState[influencer_id][column] = value
    })
    setCampaignData(nextData)
  }

  const activeCell = useRef(null)

  useEffect(() => {
    const load = async () => {
      if (params.talentSheetId) {
        let url = `/api/talent-sheets/${params.talentSheetId}?sheet_type=campaign`
        const response = await axios.get(url)
        const sheet = response.data[0]
        const campaignDataResponse = await axios.get(`/api/user/campaign/${params.talentSheetId}`)
        if (campaignDataResponse.data.sheet_id !== params.talentSheetId) {
          setError('Unable to load campaign data!')
        } else {
          setCampaignData(campaignDataResponse.data.campaign_data)
        }

        // dispatch(setSheet(sheet))
      }
    }

    load()
  }, [dispatch, params.talentSheetId])

  const handleSave = () => {
    axios
      .post('/api/user/campaign/save', {
        talent_sheet_id: params.talentSheetId,
        campaign_data: campaignData,
      })
      .then((res) => {
        setSaved('Saved')
        window.setTimeout(() => {
          setSaved(null)
        }, 2000)
        return
      })
      .catch((e) => {
        const error = _.get(e, 'response.data.error')
        setError(error)
        window.setTimeout(() => {
          setError(null)
        }, 2500)
      })
  }

  const tableColumns = [
    {
      header: 'Talent',
      td: (inf) => {
        return inf.fullname
      },
    },
  ]

  const editableColumns = [
    {
      name: 'Date Sent',
      width: '7em',
      placeholder: 'mm/dd/yy',
    },
    {
      name: 'Sender',
      width: '11em',
    },
    {
      name: 'Contact',
      width: '11em',
    },
    {
      name: 'Replied',
      width: '1em',
      placeholder: 'Y/N',
    },
    {
      name: 'Rate',
      width: '7em',
    },
  ]

  return (
    <div className="container pt-3">
      <h2>{reduxSheet.title} </h2>
      <p>
        <Link to={`/campaign/${params.talentSheetId}`}>Edit Talent or Title</Link>
      </p>
      <div className="mb-2 d-flex flex-direction-row">
        <>
          <button
            onClick={() => {
              handleSave()
            }}
            className="btn btn-primary"
            data-purpose="save-campaign-sheet"
          >
            Save
          </button>
          <div className={`message-box ${error ? 'error' : ''}`}>
            {error && <div className="ms-4">{error}</div>}
            {saved && <div className="success ms-4">Saved!</div>}
          </div>
        </>
      </div>
      <p>(Click cell to edit)</p>
      <table className="table table-striped table-bordered">
        <thead>
          <tr>
            {tableColumns.map((col, i) => {
              return <th key={`th-${i}`}>{col.header}</th>
            })}
            {editableColumns.map((col, i) => {
              return (
                <th key={`th-editable-${i}`} className="text-center">
                  {col.name}
                </th>
              )
            })}
            <th className="text-center">Notes</th>
          </tr>
        </thead>
        <tbody>
          {_.map(talentSheetInfluencers, (inf) => {
            return (
              <tr key={`inf-${inf.id}`}>
                {tableColumns.map((col, i) => {
                  return (
                    <td style={{ width: '11em' }} key={`td-${inf.id}-${i}`}>
                      {col.td(inf)}
                    </td>
                  )
                })}
                {editableColumns.map((col, i) => {
                  return (
                    <td
                      onClick={() => {
                        setEditingCell(`${inf.id}-${col.name}`)
                      }}
                      key={`editable-${i}`}
                      style={{ width: col.width }}
                      className="align-middle text-center"
                    >
                      {editingCell === `${inf.id}-${col.name}` ? (
                        <form
                          onSubmit={(e) => {
                            e.preventDefault()
                            setEditingCell(null)
                          }}
                        >
                          <input
                            autoFocus
                            style={{ width: '100%' }}
                            type="text"
                            placeholder={col.placeholder}
                            onChange={(e) => {
                              handleCellInput(inf.id, col.name, e.target.value)
                            }}
                            value={_.get(campaignData, [inf.id, col.name], '')}
                          />
                          <input type="submit" hidden />
                        </form>
                      ) : (
                        _.get(campaignData, [inf.id, col.name], '')
                      )}
                    </td>
                  )
                })}
                <td
                  onClick={() => {
                    setEditingCell(`${inf.id}-Notes`)
                    setShowModal(true)
                  }}
                >
                  {!(editingCell === `${inf.id}-Notes`) &&
                    shortenText(_.get(campaignData, [inf.id, 'Notes'], ''))}
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>
      <NotesModal
        show={showModal}
        editingCell={editingCell}
        setCampaignData={setCampaignData}
        campaignData={campaignData}
        // notes={_.get(campaignData, )}
        handleClose={() => {
          // console.log(editingCell)
          // const influencer_id = editingCell.split('-Notes')[0]
          // console.log(influencer_id)
          // console.log(campaignData)
          // handleCellInput(influencer_id, 'Notes', newNote)
          setShowModal(false)
          setEditingCell(null)
        }}
        handleCellInput={handleCellInput}
      />
    </div>
  )
}
