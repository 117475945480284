import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

import PublicProfile from './PublicProfile'
import PublicProfilev2 from './PublicProfiles/v2/PublicProfile_v2'
import TalentSheetBranding from './TalentSheetBranding'
import _ from 'lodash'
import { useParams } from 'react-router-dom'
import LoadingContainer from 'components/LoadingContainer'
import { useInView } from 'react-intersection-observer'
import { fetchSingleSheet } from 'redux/allSheetsSlice'
import mixpanel from './mixpanelBrowser'
import PublicTalentSheet from './PublicTalentSheet'

function PublicTalentSheetController() {
  const pageLimit = 10
  const [currentGroup, setCurrentGroup] = useState(1)

  const dispatch = useDispatch()
  const params = useParams()
  // const talentSheet = useSelector((state) => state.talentSheet)
  const sheet = useSelector((state) => state.allSheets.entities[params.talentSheetId])

  const influencers = useSelector((state) => state.influencers.entities)
  const socials = useSelector((state) => state.socials.entities)

  const [error, setError] = useState(null)
  const [talentLoading, setTalentLoading] = useState(false)
  const team = useSelector((state) => state.team)

  const teamSettings = useSelector((state) => {
    const team_id = sheet?.team_id
    if (!team_id) return null
    return _.get(state, ['team', 'allTeamSettings', team_id])
  })

  const loadNextGroup = () => {
    dispatch(
      fetchSingleSheet({
        hash_id: params.talentSheetId,
        page: currentGroup + 1,
        limit: pageLimit,
      }),
    )
      .then(() => {
        setCurrentGroup(currentGroup + 1)
        setTalentLoading(false)
      })
      .catch((e) => {
        setError('Error loading more')
        setTalentLoading(false)
      })
  }

  useEffect(() => {
    mixpanel.track('Viewed Talent Sheet', {
      talent_sheet_id: params.talentSheetId,
      view: 'one_sheet',
    })

    dispatch(
      fetchSingleSheet({ hash_id: params.talentSheetId, page: currentGroup, limit: pageLimit }),
    )
  }, [dispatch, params.talentSheetId])

  if (!sheet) {
    return <LoadingContainer />
  }

  const total_influencers = _.get(sheet, 'influencer_ids').length
  const totalPages = Math.ceil(total_influencers / pageLimit)

  const orderedInfluencersForDisplay = _.get(sheet, 'influencer_ids', [])
    .map((id) => {
      return influencers[id]
    })
    .filter((influencer) => influencer && influencer.with_advanced_data)

  if (_.isEmpty(orderedInfluencersForDisplay)) {
    return <LoadingContainer />
  }

  const displaySettings = teamSettings || team.settings
  const SheetProfile = displaySettings?.useOldProfile ? PublicProfile : PublicProfilev2

  return (
    <PublicTalentSheet
      talentSheet={sheet}
      orderedInfluencersForDisplay={orderedInfluencersForDisplay}
      socials={socials}
      displaySettings={displaySettings}
      SheetProfile={SheetProfile}
      loadNextGroup={loadNextGroup}
      talentLoading={talentLoading}
      setTalentLoading={setTalentLoading}
      totalPages={totalPages}
      currentGroup={currentGroup}
    />
  )
}

export default PublicTalentSheetController
