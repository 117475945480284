import { FaFacebook, FaInstagram, FaPinterest, FaYoutube, FaSnapchat } from 'react-icons/fa'

import { FaXTwitter } from 'react-icons/fa6'

import { SiTiktok } from 'react-icons/si'

export default function SocialIcon(props) {
  switch (props.platform) {
    case 'instagram':
      return <FaInstagram {...props} color={props.color ? props.color : `#D84B77`} />
    case 'tiktok':
      return <SiTiktok {...props} />
    case 'twitter':
      return <FaXTwitter {...props} />
    case 'facebook':
      return <FaFacebook {...props} />
    case 'youtube':
      return <FaYoutube {...props} color={props.color ? props.color : '#EA4234'} />
    case 'pinterest':
      return <FaPinterest {...props} />
    case 'snapchat':
      return <FaSnapchat {...props} />
    default:
      return null
  }
}
