import { fetchSingleProfileForSheet } from './redux/talentSheetSlice'
import { useAppDispatch as useDispatch, useAppSelector as useSelector } from 'hooks/hooks'
import { useEffect, useState } from 'react'

import LoadingContainer from './components/LoadingContainer.js'
import PublicProfilev2 from 'PublicProfiles/v2/PublicProfile_v2'
import { fetchSingleSheet } from 'redux/allSheetsSlice'
import mixpanel from './mixpanelBrowser.js'
import { sendEvent } from 'analytics'
import { Talent } from 'helpers/Talent'
import { Fragment } from 'react'
import { FaHeart } from 'react-icons/fa'
import 'styles/_TalentSheetResponse.scss'
import SocialIcon from 'components/SocialIcon'

import axios from 'axios'
import { Modal } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import _ from 'lodash'
import { joinClassNames, getContrastingTextForBackground } from 'utils'

const SubmitModal = ({ showModal, handleClose, responseForm, setResponseForm, handleSubmit }) => {
  const navigate = useNavigate()
  const params = useParams()

  return (
    <Modal size="lg" show={showModal} onHide={handleClose}>
      <Modal.Body
        className="p-3"
        // style={{
        //   padding: 0,
        // }}
      >
        <div className={joinClassNames(['container', 'bg-white', 'p-2'])}>
          <div className="row mt-3">
            <h3 className="text-center">Please provide your contact information</h3>
          </div>
          <div className="row mt-3">
            <div className="col-md-6 mb-3">
              <label htmlFor="responderName" className="form-label">
                Name:
              </label>
              <input
                type="text"
                className="form-control"
                id="responderName"
                value={responseForm.responderName}
                onChange={(e) =>
                  setResponseForm({ ...responseForm, responderName: e.target.value })
                }
                placeholder="Your name"
              />
            </div>
            <div className="col-md-6 mb-3">
              <label htmlFor="responderEmail" className="form-label">
                Email:
              </label>
              <input
                type="email"
                className="form-control"
                id="responderEmail"
                value={responseForm.responderEmail}
                onChange={(e) =>
                  setResponseForm({ ...responseForm, responderEmail: e.target.value })
                }
                placeholder="Your email"
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col">
              <label htmlFor="generalComments" className="form-label">
                Other Comments (Optional):
              </label>
              <textarea
                className="form-control"
                id="generalComments"
                rows="4"
                value={responseForm.comments}
                onChange={(e) => setResponseForm({ ...responseForm, comments: e.target.value })}
                placeholder="Your comments here..."
              ></textarea>
            </div>
          </div>
          <div className="row mt-3">
            <div className="col text-center">
              <button className="btn btn-primary btn-lg" onClick={handleSubmit}>
                Send Response
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default function TalentSheetResponse() {
  const pageLimit = 50

  const user = useSelector((state) => state.user)

  const [responseForm, setResponseForm] = useState({
    liked_influencers: [],
    responderName: '',
    responderEmail: '',
    comments: '',
  })

  const [formSubmitted, setFormSubmitted] = useState(false)

  useEffect(() => {
    if (user.authed && user.settings && user.settings.email) {
      setResponseForm((prevState) => ({
        ...prevState,
        responderEmail: user.settings?.email || '',
        responderName: user.settings?.name || '',
      }))
    }
  }, [user])

  const [showSubmitModal, setShowSubmitModal] = useState(false)

  const params = useParams()
  const dispatch = useDispatch()
  const searchParams = new URLSearchParams(window.location.search)
  const currentPage = Number(searchParams.get('page')) || 1

  const navigate = useNavigate()
  const talentSheet = useSelector((state) => state.allSheets.entities[params.talentSheetId])
  const team = useSelector((state) => state.team)

  let influencer_ids = talentSheet ? talentSheet.influencer_ids : []
  const influencers = useSelector((state) => state.influencers.entities)
  const socials = useSelector((state) => state.socials.entities)

  let pageInfluencerIds
  let alreadyFetched = []
  if (!_.isEmpty(influencer_ids) && influencers) {
    pageInfluencerIds = influencer_ids.slice(
      (currentPage - 1) * pageLimit,
      currentPage * pageLimit,
    )
    alreadyFetched = pageInfluencerIds
      .map((iid) => {
        return influencers[iid]
      })
      .filter((i) => i)
  }

  useEffect(() => {
    if (searchParams.get('influencer_id')) {
      const talentSheetId = params.talentSheetId
      dispatch(
        fetchSingleProfileForSheet({
          influencer_id: searchParams.get('influencer_id'),
          salesheet_id: talentSheetId,
        }),
      )
    }
  }, [dispatch, params.talentSheetId, searchParams.get('influencer_id')])

  useEffect(() => {
    // mixpanel.track('Viewed Talent Sheet Response page', {
    //   talent_sheet_id: params.talentSheetId,
    //   view: 'roster',
    // })
    sendEvent('sheet_response_view', {
      sheet_id: params.talentSheetId,
      view: 'roster',
    })

    dispatch(
      fetchSingleSheet({
        hash_id: params.talentSheetId,
        page: 1,
        limit: pageLimit,
        with_stats: false,
      }),
    )
  }, [dispatch, params.talentSheetId, pageLimit])

  const sheetTeamSettings = useSelector((state) => {
    if (!talentSheet?.team_id) return null
    return state.team.allTeamSettings[talentSheet.team_id]
  })

  if (talentSheet && !_.isEmpty(talentSheet.error)) {
    return (
      <div style={{ paddingTop: '80px' }} className="my-3">
        <div className="logo-container mb-3">
          <img
            src="https://us-east-1.linodeobjects.com/tpp-content/logos/irFa-tpp_logo.jpeg"
            alt="Company Logo"
            className="company-logo"
          />
        </div>

        <h3 className="text-center">{talentSheet.error}</h3>
      </div>
    )
  }

  if (_.isEmpty(alreadyFetched)) {
    return <LoadingContainer />
  }

  let displayedInfluencers = influencer_ids.map((iid) => influencers[iid]).filter((inf) => inf)

  if (_.isEmpty(displayedInfluencers)) {
    return <LoadingContainer />
  }

  const handleLike = (influencerId) => {
    setResponseForm((prevForm) => {
      const isAlreadyLiked = prevForm.liked_influencers.includes(influencerId)
      return {
        ...prevForm,
        liked_influencers: isAlreadyLiked
          ? prevForm.liked_influencers.filter((id) => id !== influencerId)
          : [...prevForm.liked_influencers, influencerId],
      }
    })
  }

  const handleSubmit = () => {
    // Your submission logic here. E.g., sending the responseForm data to an API.
    console.log(responseForm)
    axios
      .post('/api/talent-sheet-response-form', {
        ...responseForm,
        talent_sheet_id: params.talentSheetId,
        responder_email: responseForm.responderEmail,
        responder_name: responseForm.responderName,
      })
      .then((res) => {
        console.log(res)
        setFormSubmitted(true)
        // navigate(`/sheet/${params.talentSheetId}/response/thank-you`)
      })
      .catch((err) => {
        console.log(err)
        // navigate(`/sheet/${params.talentSheetId}/response/thank-you`)
      })
  }

  if (formSubmitted) {
    return (
      <div className="container bg-light p-5 rounded">
        <h3 className="text-center py-3 mb-5">
          Thank you for your response! Someone will be in touch soon
        </h3>
        <p className="text-center">
          <button className="btn btn-primary" onClick={() => navigate(-1)}>
            Go Back
          </button>
        </p>
      </div>
    )
  }

  return (
    <div className="container bg-light p-5 rounded">
      <h3 className="text-center py-3 mb-5">Click the creators below to show your interest</h3>
      <div className="row mt-1">
        {displayedInfluencers.map((influencer) => {
          const talent = new Talent(influencer, socials)
          const talentSocials = talent.getSocials()
          const isLiked = responseForm.liked_influencers.includes(influencer.id)
          const displayName = influencer.team_settings?.custom_display_name
            ? influencer.team_settings.custom_display_name
            : influencer.fullname

          return (
            <div key={influencer.id} className="col-md-4 mb-4">
              <div
                onClick={() => handleLike(influencer.id)}
                className={joinClassNames(['card', isLiked ? 'shadow-sm' : null])}
              >
                <div className="row no-gutters">
                  <div className="col-md-6">
                    <img
                      // onClick={() => handleLike(influencer.id)}
                      src={talent.getPhotoUrl()}
                      alt={displayName}
                      className="card-img selectable"
                      style={{ height: '200px', objectFit: 'cover', objectPosition: 'center top' }}
                    />
                  </div>
                  <div className="col-md-6">
                    <div className="card-body">
                      <div className="text-center mb-2">
                        <h5
                          className={`card-title selectable ${isLiked ? 'fw-bold' : ''}`}
                          // onClick={() => handleLike(influencer.id)}
                        >
                          {displayName}
                        </h5>
                        <div className="mb-3" style={{ height: '24px' }}>
                          {' '}
                          {isLiked && <FaHeart color="red" className="heart-icon" />}
                        </div>
                      </div>
                      {/* <div className="d-flex flex-row justify-content-around mt-2">
                        {talentSocials.allSocials.map((social) => {
                          return (
                            <div key={`social-icon-${social.id}`}>
                              <SocialIcon platform={social.platform} size={24} />
                            </div>
                          )
                        })}
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )
        })}
      </div>
      <div className="row mt-3">
        <div className="col text-center">
          <button
            className="btn btn-primary"
            onClick={() => {
              setShowSubmitModal(true)
              setResponseForm((prevForm) => ({
                ...prevForm,
                responderEmail: user.settings?.email || user.email || '',
                responderName: user.settings?.full_name || '',
              }))
            }}
          >
            Send Response
          </button>
        </div>
      </div>{' '}
      <SubmitModal
        showModal={showSubmitModal}
        handleClose={() => setShowSubmitModal(false)}
        responseForm={responseForm}
        setResponseForm={setResponseForm}
        handleSubmit={handleSubmit}
      />
    </div>
  )
}
