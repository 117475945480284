import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

import LoadingContainer from 'components/LoadingContainer'
import PublicProfilev2 from './PublicProfiles/v2/PublicProfile_v2'
import _ from 'lodash'
import { fetchPublicInfluencerProfile } from 'redux/influencerSlice'
import { useParams } from 'react-router-dom'

export default function PublicInfluencerProfile() {
  const dispatch = useDispatch()
  const params = useParams()

  useEffect(() => {
    dispatch(
      fetchPublicInfluencerProfile({ influencer_id: params.influencerId, team_id: params.teamId }),
    )
  }, [params.talentSheetId])

  const influencer = useSelector((state) => state.influencers.entities[params.influencerId])
  const socials = useSelector((state) => state.socials.entities)

  const team = useSelector((state) => state.team)

  const sheet = {
    settings: team.settings,
    team_image_url: team.image_url,
    team_name: team.name,
  }

  const teamSettings = useSelector((state) => {
    if (!params.teamId) return null
    return state.team.allTeamSettings[params.teamId]
  })

  if (teamSettings) {
    sheet.settings = teamSettings
  }

  if (_.isEmpty(influencer)) {
    return <LoadingContainer />
  }

  return (
    <div>
      <div className="">
        <PublicProfilev2
          influencer={influencer}
          socials={socials}
          settings={{
            ...sheet.settings,
            teamLogo: sheet.team_image_url,
            teamName: sheet.team_name,
            excluded_post_ids: _.get(influencer, 'team_settings.excluded_post_ids', []),
          }}
          openAccess={true}
          displaySettings={sheet.settings}
        />
      </div>
    </div>
  )
}
