import { createAsyncThunk, createEntityAdapter, createSlice } from '@reduxjs/toolkit'

import _ from 'lodash'
import axios from 'axios'

export const fetchSheetLikes = createAsyncThunk('likes/get', async ({ salesheet_id, team_id }) => {
  const response = await axios.get(`/api/user/influencer-likes`, {
    params: {
      salesheet_id,
      team_id,
    },
  })
  return _.get(response, 'data')
})

export const addInfluencerLike = createAsyncThunk(
  'likes/add',
  async ({ influencer_id, salesheet_id, team_id }) => {
    const response = await axios.post(`/api/user/influencer-likes`, {
      influencer_id,
      salesheet_id,
      team_id,
    })
    if (response.data.success) {
      return {
        influencer_id,
        salesheet_id,
        team_id,
      }
    } else {
      return {}
    }
  },
)

export const removeInfluencerLike = createAsyncThunk(
  'likes/remove',
  async ({ influencer_id, salesheet_id, team_id }) => {
    const response = await axios.delete(`/api/user/influencer-likes`, {
      params: {
        influencer_id,
        salesheet_id,
        team_id,
      },
    })
    if (response.data.success) {
      return {
        influencer_id,
        salesheet_id,
        team_id,
      }
    } else {
      return {}
    }
  },
)

export const influencerLikesSlice = createSlice({
  name: 'influencerLikes',
  initialState: {
    activeSheetLikes: [],
  },
  reducers: {},
  extraReducers: function (builder) {
    builder.addCase(addInfluencerLike.fulfilled, (state, { payload }) => {
      const { influencer_id, salesheet_id } = payload
      state.activeSheetLikes.push({
        influencer_id,
      })
    })
    builder.addCase(fetchSheetLikes.fulfilled, (state, { payload }) => {
      console.log('ok')
      console.log(payload)
      const { salesheet_id, likes } = payload
      state.activeSheetLikes = likes
    })
    builder.addCase(removeInfluencerLike.fulfilled, (state, { payload }) => {
      const { influencer_id } = payload
      state.activeSheetLikes = state.activeSheetLikes.filter(
        (like) => like.influencer_id !== influencer_id,
      )
    })
  },
})

export default influencerLikesSlice.reducer
