import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  createSelector,
  PayloadAction,
} from '@reduxjs/toolkit'
import _ from 'lodash'

import { fetchInfluencers, fetchSingleInfluencer } from './influencerSlice'

import { fetchPublicInfluencerProfile, fetchPublicInfluencers } from './influencerSlice'
import { loadPublicSheet, fetchSingleProfileForSheet } from './talentSheetSlice'
import { fetchSingleSheet } from './allSheetsSlice'
import { fetchOneUserSheet } from './userSheetSlice'
import axios from 'axios'

import { Social } from 'types'

interface FetchExtraDataPayload {
  influencer_id: string
}

interface SocialExtraData {
  id: string
  extra_data: Record<string, unknown>
}

interface ExtraData {
  id: string
  followers_count: number
  following_count: number
  engagement_rate: number
  socials: SocialExtraData[]
}

export const fetchExtraData = createAsyncThunk<ExtraData, FetchExtraDataPayload>(
  'socials/fetchExtraData',
  async ({ influencer_id }) => {
    const { data } = await axios.get<ExtraData>(
      `/api/user/influencers/extra-data/${influencer_id}`,
    )
    return data
  },
)

const socialsAdapter = createEntityAdapter<Social>()

export const socialSlice = createSlice({
  name: 'socials',
  initialState: socialsAdapter.getInitialState({
    loading: 'idle',
  }),
  reducers: {},
  extraReducers: (builder) =>
    builder
      .addCase(fetchPublicInfluencerProfile.fulfilled, (state, { payload }) => {
        socialsAdapter.upsertMany(state, payload.socials)
      })
      .addCase(fetchSingleInfluencer.fulfilled, (state, { payload }) => {
        if (payload.error) return
        socialsAdapter.upsertMany(state, payload.socials)
      })
      .addCase(fetchInfluencers.fulfilled, (state, { payload }) => {
        socialsAdapter.upsertMany(state, payload.socials)
      })
      .addCase(fetchSingleProfileForSheet.fulfilled, (state, { payload }) => {
        socialsAdapter.upsertMany(state, payload.socials)
      })
      .addCase(loadPublicSheet.fulfilled, (state, { payload }) => {
        if ('error' in payload) return // type guard
        socialsAdapter.upsertMany(state, payload.socials)
      })
      .addCase(fetchSingleSheet.fulfilled, (state, { payload }) => {
        if (payload.error) return
        socialsAdapter.upsertMany(state, payload.socials)
      })
      .addCase(fetchPublicInfluencers.fulfilled, (state, { payload }) => {
        if (payload.error) return
        socialsAdapter.upsertMany(state, payload.socials)
      })
      .addCase(fetchOneUserSheet.fulfilled, (state, { payload }) => {
        socialsAdapter.upsertMany(state, payload.socials)
      })
      .addCase(fetchExtraData.fulfilled, (state, { payload }) => {
        console.log(payload.socials)
        socialsAdapter.updateMany(
          state,
          payload.socials.map((s) => ({
            id: s.id,
            changes: {
              extra_data: s.extra_data,
            } as Partial<Social>,
          })),
        )
      }),
})

export default socialSlice.reducer
