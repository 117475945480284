import _ from 'lodash'
import { useEffect, useState, useRef } from 'react'

import SocialIcon from './components/SocialIcon'
import { joinClassNames, safelyMillify } from './utils'
import { sendEvent } from 'analytics'
import { useParams } from 'react-router-dom'

function TalentSheetRosterItemFullCover({
  profile,
  handleClick,
  displaySettings = {
    displayRosterNameColor: 'black',
  },
  source = 'Talent Sheet',
}) {
  const picture_url = profile.custom_picture || profile.socials[0].picture_main_url
  const [overlayHeight, setOverlayHeight] = useState(null)
  const [overlayWidth, setOverlayWidth] = useState(null)
  const params = useParams()

  // We will use this to get the height of the image and set the height of the overlay, TODO
  const imageRef = useRef(null)
  const reflectionRef = useRef(null)

  useEffect(() => {
    function handleResize() {
      // If the window is currently being printed, return early
      if (window.matchMedia('print').matches) {
        return
      }

      setOverlayHeight(reflectionRef.current && reflectionRef.current.offsetHeight)
      setOverlayWidth(reflectionRef.current && reflectionRef.current.offsetWidth)
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [])

  useEffect(() => {
    setOverlayHeight(reflectionRef.current && reflectionRef.current.offsetHeight)
    setOverlayWidth(reflectionRef.current && reflectionRef.current.offsetWidth)
  }, [reflectionRef])

  let nameFontModifier = null
  if (profile.fullname?.length > 18) nameFontModifier = 'fs-4'
  if (profile.fullname?.length > 30) nameFontModifier = 'fs-5'

  let customNameFont =
    displaySettings.displayTalentNameFont || displaySettings.displayRosterTitleFont || 'barlow'

  const lowerDisplayStyles = {
    backgroundImage: `url(${picture_url})`,
  }

  if (displaySettings.displayPortraitsBW) {
    lowerDisplayStyles.filter = 'grayscale(100%)'
    lowerDisplayStyles.transition = 'filter 0.5s ease'
  }

  return (
    <div
      data-influencer-id={profile.id}
      className="col-md-6 col-lg-4 roster-influencer-holder mb-4"
    >
      <div className="roster-profile-container">
        <div className="image-reflection-container shadow-sm">
          <img
            ref={imageRef}
            onClick={() => {
              handleClick()
            }}
            className={joinClassNames([
              `reflection-image selectable`,
              displaySettings.displayPortraitsBW ? `black-and-white` : null,
            ])}
            src={picture_url}
          />
          <div
            ref={reflectionRef}
            className={joinClassNames(['reflection'])}
            style={lowerDisplayStyles}
          ></div>
          <div className="overlay">
            <div
              className={`roster-profile-overlay`}
              style={{
                height: overlayHeight ? `${overlayHeight}px` : undefined,
                width: overlayWidth ? `${overlayWidth}px` : undefined,
              }}
            >
              <div className="d-flex flex-column justify-content-around pt-2">
                <div className="mb-2 d-flex flex-column align-items-center justify-content-center">
                  <div
                    onClick={() => {
                      handleClick()
                    }}
                    className={joinClassNames([
                      `selectable roster-name`,
                      displaySettings.displayRosterNameColor
                        ? `color-${displaySettings.displayRosterNameColor}`
                        : 'color-black',
                      customNameFont ? `font-${customNameFont}` : null,
                      'text-center',
                      nameFontModifier,
                      `influencer-name`,
                    ])}
                  >
                    {profile.fullname}
                  </div>
                  <div style={{ fontSize: '1em', minHeight: '1.5em' }} className="text-center">
                    {profile.tagline}
                  </div>
                </div>

                <div className="d-flex flex-column">
                  <div
                    className={joinClassNames([
                      `d-flex flex-wrap justify-content-around`,
                      `align-items-center social-follower-counts text-center mb-0`,
                    ])}
                  >
                    {profile.socials.map((social, i) => {
                      return (
                        <div
                          className="d-flex flex-column align-items-center mb-0 roster-social-icon py-0"
                          key={`profile-${social.id}`}
                        >
                          <div className="mb-0">
                            <a
                              className="text-black"
                              onClick={() => {
                                sendEvent('social_view', {
                                  influencer_id: profile.id,
                                  platform: social.platform,
                                  social_id: social.id,
                                  sheet_id: params.talentSheetId,
                                  team_id: params.teamId,
                                  from_roster: source === 'Public Roster',
                                })
                              }}
                              href={social.profile_url}
                              target="_blank"
                              rel="noreferrer"
                            >
                              <SocialIcon size="36" platform={social.platform} />
                            </a>
                          </div>
                          <div className="text-center rounded-pill follower-display p-0">
                            {safelyMillify(social.followers)}
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            zIndex: 2,
          }}
          className="d-flex flex-column justify-content-end roster-box shadow selectable"
        ></div>
      </div>
    </div>
  )
}

export default TalentSheetRosterItemFullCover
