import { joinClassNames } from 'utils'

const TeamHeader = ({ team }) => {
  if (!team) return null
  return (
    <div
      className="d-flex justify-content-center"
      style={{
        maxWidth: '100%',
        maxHeight: '200px',
      }}
    >
      {team.image_url ? (
        <img
          style={{
            maxWidth: '100%',
            // maxHeight: '200px',
          }}
          className={joinClassNames([
            'logo-border',
            team.settings?.displayDarkLogo ? 'bg-shine-black' : null,
          ])}
          src={team.image_url}
        />
      ) : (
        <h2
          className="text-black py-3"
          style={{
            fontSize: '3rem',
          }}
        >
          {team.team_name}
        </h2>
      )}
    </div>
  )
}

export default TeamHeader
