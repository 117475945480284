import { useParams } from 'react-router-dom'
import { setTags } from 'redux/influencerSlice'
import { useDispatch, useSelector } from 'react-redux'
import { useState } from 'react'

import AutoSuggest from 'components/AutoSuggest'
import _ from 'lodash'
import { addTags } from 'redux/tagsSlice'
import axios from 'axios'

export default function AddInfluencerTags({ influencer_id, ...props }) {
  const dispatch = useDispatch()

  const influencer = useSelector((state) => state.influencers.entities[influencer_id])

  const [tagsNotify, setTagsNotify] = useState(null)
  const [error, setError] = useState(null)
  const suggestedTags = useSelector((state) => state.tags.tags)

  const tags = _.get(influencer, 'tags', [])

  const performSave = async (tags = []) => {
    await axios.post('/api/user/influencers/tags', {
      influencer_id: influencer_id,
      tags,
    })
    dispatch(
      addTags({
        tags,
        influencer_id: influencer_id,
      }),
    )
  }

  const handleAddTagFromForm = (tag) => {
    if (tag.trim() === '') return

    const newTags = _.uniq([...tags, tag])
    performSave(newTags)

    dispatch(setTags({ tags: newTags, influencer: influencer_id }))
  }

  return (
    <>
      <AutoSuggest
        handleAddTagFromForm={handleAddTagFromForm}
        currentTags={tags}
        handleRemoveTag={(t) => {
          const newTags = _.without(tags, t)
          performSave(newTags)
          dispatch(setTags({ tags: newTags, influencer: influencer_id }))
        }}
        allChoices={suggestedTags}
        allowCreation={true}
        {...props}
      />
      <div className="message-box mt-2">
        {tagsNotify && <div className="success">{tagsNotify}</div>}
        {error && <div className="error">{error}</div>}
      </div>
    </>
  )
}
