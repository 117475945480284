import React, { useEffect, useState } from 'react'
import { changeSetting, fetchSettings } from './redux/userSlice'
import { useDispatch, useSelector } from 'react-redux'

import { Link } from 'react-router-dom'
import { MdOutlinePersonRemove } from 'react-icons/md'
import _ from 'lodash'
import axios from 'axios'
import { Slide, toast } from 'react-toastify'

export default function ManageOrganization({ teamMembers, refreshTeamMembers }) {
  const showSavedToast = ({ customText = 'Contact Information Saved' } = {}) => {
    toast.success(customText, {
      transition: Slide,
      position: 'top-center',
      autoClose: 1500,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: false,
      draggable: false,
      progress: undefined,
      theme: 'light',
    })
  }

  const user = useSelector((state) => state.user)
  const [saveNotification, setNotification] = useState(null)
  const [newUser, setNewUser] = useState({
    full_name: '',
    email: '',
    is_admin: false,
  })

  const [contactInfo, setContactInfo] = useState({
    email: '',
    phone: '',
    homepage: '',
    bio: '',
  })

  useEffect(async () => {
    const { data } = await axios.get('/api/user/team-info')
    setContactInfo({
      email: data.contact_email,
      phone: data.phone_number,
      homepage: data.homepage,
      bio: data.bio,
    })
  }, [user.primary_team_id])

  const handleSubmit = (e) => {
    e.preventDefault()
    const nickname = newUser.full_name.trim()
    const data = {
      full_name: newUser.full_name.trim(),
      nickname,
      email: newUser.email.trim(),
      is_admin: newUser.is_admin,
    }
    console.log(data)
    axios.post('/api/user/team/member/add', data).then((res) => {
      console.log(res)
      console.log('User added successfully')
      refreshTeamMembers()
    })
  }

  if (!user) {
    return 'Loading...'
  }

  return (
    <div className="container mt-2">
      <>
        <h3 className="text-center my-3">Contact Information</h3>
        <p>
          All fields are optional. This information will be shown when users click on your
          organization's logo at the top of each talent sheet or collection
        </p>
        <div className="row justify-content-center">
          <form
            onSubmit={(e) => {
              e.preventDefault()
              axios
                .post(`/api/user/team/contact-info`, {
                  ...contactInfo,
                })
                .then((res) => {
                  console.log('Team info updated')
                  showSavedToast()
                })
                .catch((err) => {
                  console.log(err)
                })
            }}
            className="col-md-8"
          >
            <div className="mb-3">
              <label htmlFor="contactEmail" className="form-label">
                Contact Email
              </label>
              <input
                type="text"
                className="form-control"
                id="contactEmail"
                value={contactInfo.email}
                onChange={(e) => {
                  setContactInfo({
                    ...contactInfo,
                    email: e.target.value,
                  })
                }}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="contactPhone" className="form-label">
                Phone
              </label>
              <input
                type="text"
                className="form-control"
                id="contactPhone"
                value={contactInfo.phone}
                onChange={(e) => {
                  setContactInfo({
                    ...contactInfo,
                    phone: e.target.value,
                  })
                }}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="contactHomepage" className="form-label">
                Homepage
              </label>
              <input
                type="text"
                className="form-control"
                id="contactHomepage"
                value={contactInfo.homepage}
                onChange={(e) => {
                  setContactInfo({
                    ...contactInfo,
                    homepage: e.target.value,
                  })
                }}
              />
            </div>
            <div className="mb-3">
              <label htmlFor="contactBio" className="form-label">
                Description
              </label>
              <textarea
                rows="3"
                type="text"
                className="form-control"
                id="contactHomepage"
                value={contactInfo.bio}
                onChange={(e) => {
                  setContactInfo({
                    ...contactInfo,
                    bio: e.target.value,
                  })
                }}
              />
            </div>
            <div className="mb-3 mt-1">
              <input type="submit" className="btn btn-primary" value="Save Contact Information" />
            </div>
          </form>
        </div>
      </>
      <hr />
      <div className="row">
        <h3 className="text-center">Organization Members</h3>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Full Name</th>
              <th>Email</th>
              <th className="text-center">Remove Account</th>
            </tr>
          </thead>
          <tbody>
            {teamMembers.map((member) => (
              <tr key={member.id}>
                <td>{member.full_name}</td>
                <td>{member.email}</td>
                <td
                  className="selectable text-center"
                  onClick={() => {
                    if (
                      window.confirm(
                        'Are you sure you want to remove this user from your organization?',
                      )
                    ) {
                      axios
                        .post(`/api/user/team/member/remove`, {
                          user_id: member.id,
                        })
                        .then((res) => {
                          refreshTeamMembers()
                        })
                        .catch((err) => {
                          console.log(err)
                        })
                    } else {
                      console.log('cancel removal')
                    }
                  }}
                >
                  <MdOutlinePersonRemove size={28} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <hr />
      </div>
      {/* <hr /> */}
      {/* <div className="row my-3">
        <h3>Add New Account</h3>
        <form onSubmit={handleSubmit} className="w-50">
          <div className="mb-3">
            <label htmlFor="exampleInputEmail1" className="form-label">
              Email address
            </label>
            <input
              type="email"
              className="form-control"
              id="exampleInputEmail1"
              aria-describedby="emailHelp"
              value={newUser.email}
              onChange={(e) => setNewUser({ ...newUser, email: e.target.value })}
            />
          </div>
          <div className="mb-3">
            <label htmlFor="input2" className="form-label">
              Full Name
            </label>
            <input
              type="text"
              className="form-control"
              id="input2"
              value={newUser.full_name}
              onChange={(e) => {
                setNewUser({ ...newUser, full_name: e.target.value })
              }}
            />
            <div className="form-check my-2">
              <label className="form-check-label" htmlFor="adminCheckBox">
                Allow Account to Manage Organization
              </label>
              <input
                type="checkbox"
                id="adminCheckBox"
                className="form-check-input"
                checked={newUser.is_admin}
                onChange={(e) => setNewUser({ ...newUser, is_admin: e.target.checked })}
              />
            </div>
          </div>
          <div className="mb-3 d-flex justify-content-center">
            <input type="submit" className="btn btn-primary" value="Add Account" />
          </div>
          <div className="message-box">{saveNotification && saveNotification}</div>
        </form>
      </div> */}
    </div>
  )
}
