import { Rings } from 'react-loader-spinner'

export default function LoadingContainer({ customText = 'Loading...' }) {
  return (
    <div className="container">
      <div className="d-flex flex-column justify-content-center">
        <div>
          <p className="text-center text-bold mt-3">{customText}</p>
        </div>
        <Rings
          height="80"
          width="80"
          color="#5863f8ff"
          radius="6"
          wrapperStyle={{}}
          wrapperClass="justify-content-center"
          visible={true}
          ariaLabel="rings-loading"
        />
      </div>
    </div>
  )
}
