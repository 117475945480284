import axios from 'axios'
import _ from 'lodash'

export const sendEvent = async (event_type, data) => {
  const dataCopy = _.pickBy(data, _.identity)

  const response = await axios.post('/api/analytics', {
    event_type,
    data: dataCopy,
  })
  return response.data
}
