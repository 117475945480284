import React from 'react'
import { Modal } from 'react-bootstrap'

const ContactInfoModal = ({
  showModal,
  handleClose,
  logoUrl,
  bio,
  email,
  phoneNumber,
  homepage,
}) => {
  return (
    <Modal size="lg" show={showModal} onHide={handleClose}>
      <Modal.Body style={{ padding: 0 }}>
        <div className="container p-3">
          <div className="row d-flex justify-content-center">
            <div className="col-12 col-md-9 d-flex justify-content-center align-items-center">
              <div>
                {logoUrl && (
                  <div className="logo-container text-center mb-2">
                    <img src={logoUrl} alt="Company Logo" style={{ maxWidth: '220px' }} />
                  </div>
                )}
                <p className="text-center">{bio}</p>

                <hr />

                <div className="contact-info row justify-content-center">
                  <div className="col-md-8">
                    <h2 className="mb-3">Contact Information</h2>
                    {email && (
                      <p>
                        <strong>Email:</strong> {email}
                      </p>
                    )}
                    {phoneNumber && (
                      <p>
                        <strong>Phone:</strong> {phoneNumber}
                      </p>
                    )}
                    {homepage && (
                      <p>
                        <strong>Homepage:</strong>{' '}
                        <a target="_blank" href={homepage}>
                          {homepage}
                        </a>
                      </p>
                    )}
                  </div>
                </div>

                <button onClick={handleClose} className="btn btn-link">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ContactInfoModal
